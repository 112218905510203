import styles from "./Bottom.module.css";



const Bottom = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };
  return (
    <div className={styles.banner}>
      <div className={styles.overflowImage}>
      <div className={styles.overlay}>
        <h1 className={styles.title}>Let’s Transform Your Business with Zoho CRM</h1>
        <p className={styles.text}>
          Contact us today to book a <strong>FREE consultation</strong> and discover how our expertise 
          can help streamline your CRM processes and accelerate your business growth.
        </p>
        <button onClick={scrollToTop} className={styles.button}>Schedule a Call with Our Experts Today!</button>
      </div>
      </div>

    </div>
  );
};

export default Bottom;
