import React from "react";
import styles from "./NavBar.module.css"; // Import CSS module
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
const NavBar = ({ menuData,isFixed }) => {
  const menuDatas = [
    {
      name: "Business Setup",
      link: "#",
      items: [
        { name: "Offshore Company Setup", link: "#" },
        { name: "Mainland Company Setup", link: "#" },
        {
          name: "Freezone Company Setup",
          link: "#",
          items: [
            { name: "DMCC Company Setup", link: "#" },
            { name: "IFZA Freezone License Provider", link: "#" },
            {
              name: "RAKEZ Company Setup",
              link: "#",
              items: [
                { name: "RAKEZ Holding Company", link: "#" },
                { name: "RAKEZ Liquidation", link: "#" },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "About Us",
      link: "#",
      items: [
        { name: "Blogs", link: "#" },
        { name: "Career", link: "#" },
      ],
    },
  ];



  return (

<nav className={`${styles.navbar} ${!isFixed ? styles.navbar_border_top_special : ""}`}>
  
      <ul className={styles.menu}>
        {!menuData.length? [1, 2, 3, 4,5].map(() => (
                            <Skeleton
                              width={100}
                              height={20}
                              className="rounded-lg"
                              style={{ marginRight: "20px" }}
                            />
                          )) :  menuData.map((menu) => (
          <li key={menu.name} className={`${styles.menuItem} ${styles.chageColorOnHover}` }>
            <Link state={{ currPath: menu.currentPath }} className={styles.link} to={menu.link}>{menu.name}</Link> {menu.items.length > 0 && <div className={styles.arrowContainer}>
              <MdOutlineKeyboardArrowUp className={styles.up_arrow} />
              <MdOutlineKeyboardArrowDown className={styles.down_arrow} />
            </div>}
            {menu.items.length > 0 && (
              <ul className={styles.dropdown}>
                {menu.items.map((subItem) => (
                  <li key={subItem.name} className={`${styles.menuItem}`}>
                    <Link state={{ currPath: subItem.currentPath }} className={styles.link_sub} to={subItem.link}>{subItem.name}</Link> {subItem.items.length > 0 && <MdOutlineKeyboardArrowRight className={styles.right_arrow} />}
                    {subItem.items.length > 0 && (
                      <ul className={`${styles.subDropdown}`}>
                        {subItem.items.map((thirdItem) => (
                          <li key={thirdItem.name} className={styles.menuItem}>
                            <Link state={{ currPath: thirdItem.currentPath }} className={styles.link_sub_sub} to={thirdItem.link}>{thirdItem.name}</Link> {thirdItem.items.length > 0 && <MdOutlineKeyboardArrowRight className={styles.right_arrow} />}
                            {thirdItem.items.length > 0 && (
                             <ul className={styles.subDropdown}>
                                {thirdItem.items.map((fourthItem) => (
                                  <li key={fourthItem.id} className={styles.menuItem}>
                                    <Link state={{ currPath: fourthItem.currentPath }} className={styles.link_sub_sub} to={fourthItem.link}>{fourthItem.name}</Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      <a className={styles.nav_appointment} href="#freecontact" >Get An Appointment</a>

  
    </nav>

  );
};

export default NavBar;
