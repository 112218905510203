import styles from "./Zohopartner.module.css";
// import ZohoProjectCard from "./Zohoprojectcard";
import Footer from "../Footer";  
import FreeConsultation from "../Apurv/FooterContact";
// import ZohoProjectsServices from "./Projectsservices";
import React, { useState } from "react";
import AllPageHero from "../AllPageHero";
import ZohoPartnership from "./Auditing.js"
import ZohoBenefits from  "./ZohoSolutions.js"

import { useLocation  } from "react-router-dom";
import ZohoSolutions  from "./Unlock.js";
import ZohoApplications from "./ProjectCard.js"
import Elevate from "./Elevate.js"
import ZohoImplementationProcess from "./End-to-End.js"
import SEO from "../../SEO/SEO";

const Partner = () => {
  
  const location = useLocation();
  const currPath = location.state?.currPath || [];
  const [scrollDirection, setScrollDirection] = useState("down");

  return (
    <>
    <SEO title="Zoho Partner in Dubai | Elevate Accounting & Auditing" description="Elevate Accounting & Auditing is your trusted partner in Dubai for Zoho implementation and consultation services. We help businesses streamline their operations with Zoho One, from sales and marketing to finance, HR, and customer service. Book a free consultation today!" keywords="Zoho Partner Dubai, Zoho implementation services, Zoho consulting services, Zoho One implementation, Zoho One consulting, business automation software, CRM and ERP integration Dubai, enterprise software solutions UAE, Zoho One customization, cloud-based business management, sales and marketing automation, HR and finance solutions, Zoho-certified partner Dubai, digital transformation UAE, business growth solutions." url="https://elevatebpm.com/zoho-partner-dubai/" />
    <AllPageHero currPath={currPath} headingName={"Elevate Your Projects with Zoho"} />
    <div className={styles.container}>
      <div className={styles.backgroundElements}>
        <div className={styles.diagonalGrid}></div>
        <div className={styles.circles}>
          <div className={styles.circle1}></div>
          <div className={styles.circle2}></div>
          <div className={styles.circle3}></div>
        </div>
      </div>
      
      <div className={styles.imageSection}>
        <img 
          src="https://elevateauditing.com/wp-content/uploads/2024/11/zoho-partner-1-1.jpg" 
          alt="Zoho Partnership Handshake" 
          className={styles.image} 
        />
      </div>

      <div className={styles.textSection}>
        <h2>
          Welcome to Elevate Accounting &
          <br />
          Auditing – Your Zoho Authorized
          <br />
          Partner In Dubai , UAE  <span className={styles.campaignText}> Zoho Campaigns</span>
        </h2>
        <p className={styles.description}>
          At Elevate Accounting & Auditing, we are proud to be a trusted <span className={styles.descriptionb}>Zoho Authorized 
          Partner in Dubai</span>, offering expert Zoho Implementation and Consultation 
          Services for businesses across the UAE.
        </p>
      </div>
    </div>
  
    <ZohoSolutions />
    <ZohoPartnership />
    <ZohoBenefits />
    <ZohoApplications />
    <ZohoImplementationProcess />
    <Elevate />
    <FreeConsultation />
    <Footer />
    </>
  );
};

export default Partner;
