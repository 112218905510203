import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const BuildingConfidence = () => {
    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1200);
    
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    // Responsive values based on screen width
    const getFontSize = (min, max) => {
        // Calculate a value between min and max based on screen width
        const calculatedSize = min + (max - min) * ((windowWidth - 320) / (1200 - 320));
        return `${Math.min(Math.max(calculatedSize, min), max)}rem`;
    };
    
    const getSpacing = (min, max) => {
        const calculatedSpace = min + (max - min) * ((windowWidth - 320) / (1200 - 320));
        return `${Math.min(Math.max(calculatedSpace, min), max)}rem`;
    };
    
    const styles = {
        confidenceWrapper: {
            background: '#ffffff',
            padding: `${getSpacing(2, 4)} ${getSpacing(1, 2)}`,
            width: '100%',
            boxSizing: 'border-box'
        },
        confidenceContent: {
            width: windowWidth <= 768 ? '100%' : windowWidth <= 992 ? '95%' : '90%',
            maxWidth: '1200px',
            margin: '0 auto',
            fontFamily: 'Roboto, Sans-serif'
        },
        heading: {
            fontSize: getFontSize(1.75, 2.5),
            fontWeight: 'bold',
            marginBottom: getSpacing(1.25, 2),
            textAlign: 'center',
            background: 'linear-gradient(to right, #1E2B5C, #C43630)',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            color: 'transparent',
            lineHeight: '1.2',
            padding: windowWidth <= 768 ? '0 0.5rem' : '0'
        },
        textContent: {
            width: '100%',
            margin: '0 auto'
        },
        paragraph: {
            color: '#343434',
            fontSize: getFontSize(0.95, 1),
            lineHeight: windowWidth <= 480 ? '1.6' : '1.8',
            marginBottom: getSpacing(1, 1.5),
            textAlign: windowWidth <= 992 ? 'left' : 'justify',
            fontFamily: 'Roboto, Sans-serif',
            fontWeight: 'normal',
            margin: `0 0 ${getSpacing(1, 1.5)} 0`
        }
    };

    return (
        <div style={styles.confidenceWrapper}>
            <div style={styles.confidenceContent}>
                <motion.h2 
                    style={styles.heading}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    Building Confidence Through Reliable Audits
                </motion.h2>
                <div style={styles.textContent}>
                    <p style={styles.paragraph}>
                        The key to sustain confidence between your company and stakeholders is undoubtedly a reliable audit. The evolving regulatory requirements coupled with increasing stakeholder demands in terms of transparency and accountability is pressing businesses across the globe to reinstate the importance of audit and auditors in the financial reporting chain. However, the concept of audit has gone far beyond as a mere compliance constituent to a prerequisite for a strong and sound financial management. While business owners might advance and continue to venture under the impression that the company is profitably running, the actual scenario might be otherwise. An audit report aims to highlight whether the financial statements prepared by an organisations present a true and fair view of its state of affairs and if they have been prepared in accordance with the appropriate reporting framework.
                    </p>
                    
                    <p style={styles.paragraph}>
                        At Elevate, we strongly commit in thorough yet time bound audits along with a strong understanding of our customer requirements. Our well experienced team with profound understanding of relevant reporting frameworks and auditing standards ensures that our assurance report are exceptionally valuable and reliable.
                    </p>
                    
                    <p style={{...styles.paragraph, marginBottom: windowWidth <= 480 ? '0' : styles.paragraph.marginBottom}}>
                        External audits are primarily conducted for statutory purposes in compliance with the laws of the land along with an in depth analysis of the financial information presented. The prevailing regulations in the UAE require the financial statements of entities to be audited on an annual basis. External Auditors are independent to the entity being audited.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BuildingConfidence;
