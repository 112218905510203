import React from 'react';
import styles from "./Auditing.module.css"

const ZohoPartnership = () => {
  return (
    <section className={styles.partnershipSection}>
      {/* Elementos de fondo - ¡NUEVO! */}
      <div className={styles.backgroundPatterns}>
        <div className={styles.gridPattern}></div>
        <div className={styles.circlePattern}></div>
      </div>
      
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.logoColumn}>
            <img 
              src="https://www.caldere.com/wp-content/uploads/2022/01/caldere_zoho_workplace.jpg" 
              alt="Zoho Logo" 
              className={styles.zohoLogo}
            />
          </div>
          
          <div className={styles.textColumn}>
            <h2 className={styles.title}>
              Official <span className={styles.highlight}>Zoho Partners</span> in Dubai
            </h2>
            
            <div className={styles.benefits}>
              <div className={styles.benefitItem}>
                <div className={styles.checkmark}>✓</div>
                <span>Expert Zoho implementation and configuration</span>
              </div>
              <div className={styles.benefitItem}>
                <div className={styles.checkmark}>✓</div>
                <span>Customized solutions tailored to your business needs</span>
              </div>
              <div className={styles.benefitItem}>
                <div className={styles.checkmark}>✓</div>
                <span>Ongoing support and training for your team</span>
              </div>
              <div className={styles.benefitItem}>
                <div className={styles.checkmark}>✓</div>
                <span>Seamless integration with your existing systems</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ZohoPartnership;