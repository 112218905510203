import styles from "./zoho.module.css";
import ZohoProjectCard from "./Zohoprojectcard";
import Footer from "../Footer";
import FreeConsultation from "../Apurv/FooterContact";
import ZohoProjectsServices from "./Projectsservices";
import React, { useState } from "react";
import AllPageHero from "../AllPageHero";
import { useLocation } from "react-router-dom";
import Botton from "./Bottom";
import SEO from "../../SEO/SEO";


const Zohopartner = () => {

  const location = useLocation();
  const currPath = location.state?.currPath || [];
  const [scrollDirection, setScrollDirection] = useState("down");

  return (
    <>

    <SEO title="Zoho Projects | Elevate Accounting & Auditing" description="Elevate Accounting & Auditing is your trusted partner in Dubai for Zoho Projects, the leading project management software for growing businesses. We help businesses streamline their project management processes and drive business success with Zoho Projects." keywords="Zoho Projects, project management software, project collaboration, business growth, Dubai" url="https://elevatebpm.com/zoho-projects/" />
        <AllPageHero currPath={currPath} headingName={"Elevate Your Projects with Zoho"} />
    <div className={styles.container}>
      <div className={styles.textSection}>
        <h2>
          Elevate Accounting & Auditing: Your
          <span className={styles.highlight}> Trusted Zoho Partner in the UAE</span>
        </h2>
        <p className={styles.description}>
          At Elevate Accounting & Auditing, we understand that effective project 
          management is the backbone of any successful business. As an authorized 
          Zoho Partner in the UAE, we’re proud to offer Zoho Projects – a powerful, 
          all-in-one solution to plan, track, and collaborate on your projects efficiently. 
          With Zoho Projects, you can ensure that every project is completed on time, 
          within budget, and to the highest standard.
        </p>
        <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.demoButton}>Request a Demo</button>
      </div>


        <div className={styles.imageSection}>
          <img src="https://elevateauditing.com/wp-content/uploads/2024/11/Your-Trusted-Zoho-Partner-in-the-UAE.jpg"
            alt="Partnership" className={styles.image} />
        </div>
      </div>
      <ZohoProjectCard />
      <ZohoProjectsServices />
      <Botton />
      <FreeConsultation />
      <Footer />
    </>
  );
};

export default Zohopartner;
