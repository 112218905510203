 import styles from "./Frameworks.module.css"

export default function InternalAudit() {
  return (
    <section className={styles.frameworkSection}>
      <div className={styles.container}>
        <h2 className={styles.heading}>Frameworks governing internal audits define Internal Audit as follows:</h2>
        <div className={styles.contentWrapper}>
          <div className={styles.quoteBox}>
            <p className={styles.quote}>
              "Internal Audit provides an independent assurance on the effectiveness of internal controls and risk
              management processes to enhance governance and achieve organizational objectives".
            </p>
          </div>
          <p className={styles.description}>
            Internal audits are always a guiding mechanism for organizations to understand their operations in all
            dimensions and bridge the gap wherever required. Internal auditing activity is primarily directed at
            evaluating internal control. Internal Control is broadly defined as a process implemented by the management
            of the entity to ensure the achievement of the following objectives:
          </p>
        </div>
        <div className={styles.objectivesGrid}>
          <div className={styles.objectiveCard}>Efficiency & Effectiveness</div>
          <div className={styles.objectiveCard}>Compliance With Laws And Regulations</div>
          <div className={styles.objectiveCard}>Safeguarding Of Assets</div>
          <div className={styles.objectiveCard}>Ensuring Financial and Management Transparency</div>
        </div>
      </div>
    </section>
  )
}

