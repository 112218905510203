"use client"

import { useEffect, useRef } from "react"
import styles from "./ProjectCard.module.css"


const ZohoApplications = () => {
  const cardsRef = useRef([])

  const applicationCategories = [
    {
      title: "Sales & CRM Solutions",
      icon: "📊",
      applications: ["Zoho CRM", "Bigin", "Zoho Forms", "SalesIQ", "SalesInbox", "Zoho Sign", "RouteIQ", "Thrive"],
    },
    {
      title: "Marketing Solutions",
      icon: "🎯",
      applications: [
        "Zoho Social",
        "Zoho Campaigns",
        "Zoho Forms",
        "Zoho Survey",
        "Zoho Sites",
        "Zoho PageSense",
        "Zoho Backstage",
        "Zoho Webinar",
        "Zoho Events",
        "Zoho Marketing Automation",
        "Zoho LandingPage",
      ],
    },
    {
      title: "Service Solutions",
      icon: "🛎️",
      applications: ["Zoho Desk", "Zoho Assist", "Zoho Lens", "Zoho FSM", "SalesIQ"],
    },
    {
      title: "Finance & Accounting",
      icon: "💰",
      applications: [
        "Zoho Books",
        "Zoho Invoice",
        "Zoho Expense",
        "Zoho Inventory",
        "Zoho Billing",
        "Zoho Checkout",
        "Zoho Payables",
        "Zoho Sign",
      ],
    },
    {
      title: "Email & Collaboration",
      icon: "✉️",
      applications: [
        "Zoho Mail",
        "Zoho Meeting",
        "Zoho Cliq",
        "Zoho ShowTime",
        "Zoho Sheet",
        "Zoho Show",
        "Zoho Notebook",
        "Zoho Docs",
        "Zoho Connect",
        "Zoho Bookings",
        "Zoho TeamInbox",
        "Zoho WorkDrive",
        "Zoho Sign",
        "Zoho Office Suite",
      ],
    },
    {
      title: "Human Resources & Employee Management",
      icon: "👥",
      applications: [
        "Zoho People",
        "Zoho Recruit",
        "Zoho Expense",
        "Zoho Workerly",
        "Zoho Shifts",
        "Zoho Sign",
        "Zoho Payroll",
      ],
    },
    {
      title: "Business Intelligence & Analytics",
      icon: "📈",
      applications: ["Zoho Analytics", "Zoho Dataprep BI", "Zoho DataPipe"],
    },
    {
      title: "Project Management Solutions",
      icon: "📋",
      applications: ["Zoho Projects", "Zoho Sprints", "Zoho BugTracker"],
    },
    {
      title: "Developer Platforms",
      icon: "💻",
      applications: ["Zoho Creator", "Zoho Flow", "Zoho Qntrl", "Zoho Catalyst", "Zoho Office Integrator", "Zoho CRM+"],
    },
  ]

  // Intersection Observer to animate cards when they come into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate)
          }
        })
      },
      { threshold: 0.1 },
    )

    cardsRef.current.forEach((card) => {
      if (card) observer.observe(card)
    })

    return () => {
      cardsRef.current.forEach((card) => {
        if (card) observer.unobserve(card)
      })
    }
  }, [])

  return (
    <section className={styles.processSection}>
      <div className={styles.container}>
        <h2 className={styles.heading}>
          Key <span className={styles.highlight}>Zoho Applications</span> for Your Business
        </h2>
        

        <div className={styles.processGrid}>
          {applicationCategories.map((category, index) => (
            <div 
              key={index} 
              className={styles.processCard} 
              ref={(el) => (cardsRef.current[index] = el)}
            >
              <div className={styles.cardContent}>
                <div className={styles.iconTitle}>
                  <div className={styles.icon}>{category.icon}</div>
                  <h3 className={styles.title}>{category.title}</h3>
                </div>
                <ul className={styles.applicationList}>
                  {category.applications.map((app, appIndex) => (
                    <li key={appIndex} className={styles.applicationItem}>
                      <span className={styles.bulletPoint}>•</span> {app}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ZohoApplications

