import React from "react";
// import { Link } from 'react-router-dom';
// import styles from './internalaudit.module.css';
// import WhyInternalAudit from './whyInternalAudit';
// import NormalDuration from './normalduration';
// import InternalAuditProcess from './InternalAuditprocess';
// import ExpertAuditServices from '../ExternalAudit/ExpertAuditServices';

// import InternalAudit from './Frameworks';
import AllPageHero from "../AllPageHero";
import FreeConsultation from "../Apurv/FooterContact";
import Footer from "../Footer";
import { motion } from "framer-motion";
import KeyZoho from "./KeyZoho";
import OurZoho from "./OurZoho";
import ElevateAccounting from "./ElevateAccounting";
import Bottom from "./Bottom";
import Header from "../Header";
import { useLocation } from "react-router-dom";
import SEO from "../../SEO/SEO";
const ZohoPeople = () => {
  // If AllPageHero needs data, pass it as props
  const location = useLocation();
  const currPath = location.state?.currPath || [];
  return (
    <>
    <SEO title="Zoho People | Elevate Accounting & Auditing" description="Elevate Accounting & Auditing is your trusted partner in Dubai for Zoho People, the ultimate all-in-one business suite. We offer expert consultation, implementation, customization, and ongoing support to help you maximize efficiency, enhance collaboration, and drive growth. From sales and marketing to finance, HR, and customer service, streamline every aspect of your business with Zoho One. Book a free consultation today!" keywords="Zoho People Dubai, Zoho One UAE, Zoho One implementation, Zoho People consulting services, Zoho One support UAE, business automation software, CRM and ERP integration Dubai, enterprise software solutions UAE, Zoho One customization, cloud-based business management, sales and marketing automation, HR and finance solutions, Zoho-certified partner Dubai, digital transformation UAE, business growth solutions." url="https://elevatebpm.com/zoho-people" />
      <AllPageHero currPath={currPath} headingName={"Zoho People"} />
      <ElevateAccounting />
      <KeyZoho />
      <OurZoho />
      <Bottom />
      <FreeConsultation />
      <Footer />
    </>
  );
};

export default ZohoPeople;
