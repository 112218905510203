import React from 'react';
import { motion } from 'framer-motion';
import styles from './Benefits.module.css';

const Benefits = () => {
    const benefits = [
        {
            id: 1,
            text: "Ensuring legal and regulatory compliance.",
            delay: 0.2
        },
        {
            id: 2,
            text: "Detecting & preventing frauds & malpractices.",
            delay: 0.4
        },
        {
            id: 3,
            text: "Improves financial credibility.",
            delay: 0.6
        },
        {
            id: 4,
            text: "Enhances overall credibility of the company in all public fronts.",
            delay: 0.8
        },
        {
            id: 5,
            text: "Provides an unbiased opinion on the performance of the company.",
            delay: 1
        }
    ];

    return (
        <section className={styles.benefitsSection}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <div className={styles.textContent}>
                        <motion.h2 
                            className={styles.heading}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6 }}
                        >
                            <span className={styles.primary}>Benefits</span> of doing{' '}
                            <span className={styles.accent}>External Audit</span>
                        </motion.h2>

                        <motion.div
                            className={styles.benefitsList}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {benefits.map((benefit) => (
                                <motion.div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                    initial={{ opacity: 0, x: -20 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: benefit.delay }}
                                >
                                    <div className={styles.checkmark}>✓</div>
                                    <span className={styles.text}>{benefit.text}</span>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>

                    <motion.div 
                        className={styles.imageSection}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                    >
                        <img 
                            className={styles.image}
                            src="https://static.vecteezy.com/system/resources/thumbnails/007/951/672/small_2x/cityscape-on-white-background-building-perspective-modern-building-in-the-city-skyline-city-silhouette-city-skyscrapers-business-center-vector.jpg" 
                            alt="Corporate Building"
                            loading="lazy"
                        />
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default Benefits;
