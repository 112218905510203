"use client"

import { useEffect } from "react"
import styles from "./KeyZoho.module.css"
import { Target, Users, Calendar, Clock, Smartphone, BarChart3 } from "lucide-react"

const KeyZoho = () => {
  // Animation effect on scroll
  useEffect(() => {
    const cards = document.querySelectorAll(`.${styles.processCard}`)

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate)
          }
        })
      },
      { threshold: 0.1 },
    )

    cards.forEach((card) => {
      observer.observe(card)
    })

    return () => {
      cards.forEach((card) => {
        observer.unobserve(card)
      })
    }
  }, [])

  const features = [
    {
      Icon: Users,
      title: "Streamlined HR Operations",
      description: "Automate HR tasks, freeing up time for strategic initiatives while ensuring routine operations are handled seamlessly.",
      color: "#ff5733", // Red-Orange
    },
    {
      Icon: Calendar,
      title: "Centralized Employee Information",
      description: "Store all employee data securely in one system, ensuring easy access and reducing errors for better decision-making.",
      color: "#007bff", // Blue
    },
    {
      Icon: Target,
      title: "Accurate Time Tracking",
      description: "Efficiently manage attendance, leaves, and timesheets, ensuring accurate records and compliance.",
      color: "#28a745", // Green
    },
    {
      Icon: Clock,
      title: "Performance Management",
      description: "Set clear goals, track performance, and provide feedback to drive growth and enhance employee development.",
      color: "#ffc107", // Yellow
    },
    {
      Icon: Smartphone,
      title: "Seamless Onboarding",
      description: "Simplify the onboarding process with customizable workflows, allowing new hires to integrate smoothly into your organization.",
      color: "#6610f2", // Purple
    },
    {
      Icon: BarChart3,
      title: "Compliance & Insights",
      description: "Stay compliant with labor laws and generate reports to make data-driven HR decisions for your business.",
      color: "#17a2b8", // Teal
    },
  ]

  return (
    <>
    <section className={styles.processSection}>
      <div className={styles.container}>
        <h2 className={styles.heading}>Key Features of Zoho Projects</h2>
        <p className={styles.subheading}>
          Leverage powerful tools in Zoho Projects to simplify planning, tracking, and collaboration for more efficient
          project execution
        </p>

        <div className={styles.processGrid}>
          {features?.map((feature, index) => (
            <div
              key={index}
              className={`${styles.processCard} ${styles.fadeIn}`}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className={styles.cardContent}>
                <div className={styles.iconTitle}>
                  <feature.Icon className={styles.icon} stroke={feature.color} />
                  <h3 className={styles.title}>{feature.title}</h3>
                </div>
                <p className={styles.description}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.button1}>     
      Schedule Your Demo
    </button>

    </section>
    </>
  )
}

export default KeyZoho;