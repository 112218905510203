import React, { useState, useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import FreeConsultation from '../Apurv/FooterContact'
import AllPageHero from '../AllPageHero'
import { useLocation } from 'react-router-dom';
import Bottom2 from './Bottom2'
import ZohoOneComp from './ZohoOneComp'
import { motion } from 'framer-motion';
import WhyInternalAudit2 from '../Suraj/zoho/Zoho Campaigns/whyInternalAudit';
import ExpertAuditServices from '../Suraj/zoho/Zoho Campaigns/ExpertAuditServices';
import AllYour from '../Suraj/zoho/Zoho Campaigns/AllYour'
import OneZoho from '../Suraj/zoho/Zoho Campaigns/OneZoho'
import SEO from '../../SEO/SEO';


const ZohoOne = () => {
  const location = useLocation();
  const currPath = location.state?.currPath || [];
  const [scrollDirection, setScrollDirection] = useState("down");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollDirection("up");
      } else {
        setScrollDirection("down");
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, []);
  return (
    <div>

       <SEO title={"Zoho One"} description={"Transform your business operations with Zoho One, the ultimate all-in-one business suite. Elevate Accounting & Auditing, a trusted Zoho Partner in Dubai, UAE, offers expert consultation, implementation, customization, and ongoing support to help you maximize efficiency, enhance collaboration, and drive growth. From sales and marketing to finance, HR, and customer service, streamline every aspect of your business with Zoho One. Book a free consultation today!"} keywords={"Zoho One Dubai, Zoho One UAE, Zoho One implementation, Zoho One consulting services, Zoho One support UAE, business automation software, CRM and ERP integration Dubai, enterprise software solutions UAE, Zoho One customization, cloud-based business management, sales and marketing automation, HR and finance solutions, Zoho-certified partner Dubai, digital transformation UAE, business growth solutions."} url={"https://elevatebpm.com/zoho-one"}/>

        <AllPageHero currPath={currPath} headingName={"Zoho One"} />
        <ZohoOneComp />
        <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <AllYour />
      </motion.div>
      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
      <OneZoho />
      </motion.div>
      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
      <Bottom2 />
      </motion.div>
        
        <FreeConsultation />
        <Footer />
    </div>
  )
}

export default ZohoOne