
import axios from "axios";
import styles from "./FooterContact.module.css"
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const FreeConsultation = () => {
  const [buttonText, setButtonText] = useState("Request A Callback Now");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    enquiryType: "",
  })
  const [scrollDirection, setScrollDirection] = useState("down");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollDirection("up");
      } else {
        setScrollDirection("down");
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Handle form submission here
    console.log(formData)
    setButtonText("Sending...");
    try {
        const response = await axios.post("https://elevatebpm.com/admin/api/v2/getInTouchStore", {
            company_name: formData.companyName,
            full_name: formData.fullName,
            email: formData.email,
            mobile: formData.mobileNumber,
            country: "NA",
            enquiry_type: formData.enquiryType || "Consulting"
        });
            setButtonText("Request Sent");
            setFormData({
                companyName: "",
                fullName: "",
                email: "",
                mobileNumber: "",
                enquiryType: ""
            });
            console.log(response.data);
            
    } catch (error) {
        setButtonText("Request A Callback Now");
        console.error("Error:", error);
    }

  }

  return (
    <motion.div
      initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ amount: 0.009 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div id="freecontact" className={styles.footerContainer}>
        <div className={styles.glassOverlay}>
          <form onSubmit={handleSubmit} className={styles.contactForm}>
            <div className={styles.content}>
              <p className={styles.contentHeading}>REQUEST FOR OUR</p>
              <h2 className={styles.secondHeading}>Free Consultation</h2>
              <p className={styles.thirdHeading}>
                Don't miss out – take advantage of our free consultation and take the first step towards achieving your
                goals
              </p>
            </div>

            <div className={styles.formFields}>
              <div className={styles.formGroup}>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Full Name"
                  required
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "Full Name")}
                />
              </div>

              <div className={styles.formGroup}>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  required
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "Email")}
                />
              </div>

              <div className={styles.formGroup}>
                <input
                  type="tel"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  placeholder="Mobile Number"
                  required
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "Mobile Number")}
                />
              </div>

              <div className={styles.formGroup}>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Company name"
                  required
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "Company Name")}
                />
              </div>

              <div className={styles.enquiryRow}>
                <div className={styles.enquirySelect}>
                  <select
                    name="enquiryType"
                    value={formData.enquiryType}
                    onChange={handleChange}
                    required
                    className={styles.selectBox}
                  >
                    <option value="" disabled>
                      Select The Enquiry Type*
                    </option>
                    <option value="AUDITING">AUDITING</option>
                    <option value="ACCOUNTING_VAT">ACCOUNTING & VAT</option>
                    <option value="VAT">VAT</option>
                    <option value="CORPORATE_TAX">CORPORATE TAX SERVICES</option>
                    <option value="COMPANY_FORMATION">COMPANY FORMATION</option>
                    <option value="CFO_SERVICE">CFO SERVICE</option>
                    <option value="BUSINESS_VALUATION">BUSINESS VALUATION</option>
                    <option value="PAYROLL_HR">PAYROLL & HR SERVICES</option>
                    <option value="GOLDEN_VISA">GOLDEN VISA</option>
                    <option value="AML_COMPLIANCE">AML & COMPLIANCE</option>
                    <option value="DIGITAL_MARKETING">DIGITAL MARKETING</option>
                    <option value="CORPORATE_SERVICES">CORPORATE SERVICES</option>
                    <option value="ICV_CERTIFICATION">ICV CERTIFICATION SERVICE</option>
                    <option value="LIQUIDATION">LIQUIDATION</option>
                    <option value="ESR_UBO">ESR & UBO SERVICES</option>
                    <option value="BUSINESS_COLLABRATION">BUSINESS COLLABRATION</option>
                    <option value="TAX_RESIDENCY">TAX RESIDENCEY CERTIFICATE</option>
                    <option value="ACCOUNT_OPENING">ACCOUNT OPENING ASSISTANCE</option>
                    <option value="BANK_ACCOUNT_OPENING">BANK ACCOUNT OPENING ASSISTANCE</option>
                  </select>
                </div>
                <button type="submit" className={styles.submitButton}>
                {buttonText}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  )
}

export default FreeConsultation

