import React from "react";
import { Link } from "react-router-dom";

const SubMenu = ({ subMenu }) => {
  const tohidesidebar = () => {
    //if(window.innerWidth > 1024){
    var sidebarmenuvar = document.getElementById("hideonclick");
    var burgericons = document.getElementById("menove-burger-ione");
    var iconcirele = document.getElementById("iconsidebars");
    var iconsidebarstwo = document.getElementById("iconsidebarstwo");
    var iconsidebarsthree = document.getElementById("iconsidebarsthree");
    var removescrollbars = document.getElementById("body-sections");
    var removescrollbarss = document.getElementById("body-sectionss");
    removescrollbars.classList.remove("hidden-scroll");
    removescrollbarss.classList.remove("hidden-scroll");
    burgericons.classList.remove("open");
    iconcirele.classList.remove("collapse");
    iconcirele.classList.remove("rotate30");
    iconcirele.classList.remove("rotate45");
    iconsidebarstwo.classList.remove("collapse");
    iconsidebarstwo.style.display = "block";
    iconsidebarsthree.classList.remove("collapse");
    iconsidebarsthree.classList.remove("rotate150");
    iconsidebarsthree.classList.remove("rotate135");
    if (sidebarmenuvar.style.display === "none") {
      sidebarmenuvar.style.display = "block";
    } else {
      sidebarmenuvar.style.display = "none";
    }
    //}
  };

  if (!subMenu) return null;

  return (
    <div
      className="services-menu wow animated fadeInLeftBig"
      data-wow-duration="0.3s"
      style={{ display: "block" }}
    >
      <div className="cross visible1024-cross">
        <Link title="close" to="/">
          <img src="./asstes/img/images-multiply.svg" alt="Cross" />
        </Link>
      </div>
      <div className="submenu-portion">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <h2 className="h2-heading mb10">{subMenu?.title}</h2>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="row">
            {subMenu.children &&
              subMenu.children.map((sub, index) => (
                <div
                  className="col-md-4 col-sm-4 col-xs-12 align-list"
                  key={index}
                >
                  <ul className="list-unstyled li-separator nav-item dropdown">
                    <li className="nav-item dropdown" id="submenu-dropdowns">
                      <div className="">
                        <Link
                          className="nav-link dropdown-toggle"
                          onClick={tohidesidebar}
                          title={sub?.title}
                          to={"/" + sub?.url}
                          id="submenu-item"
                        >
                          {sub?.title}
                          <i
                            className={
                              sub.children.length > 0 ? "fa fa-angle-down" : ""
                            }
                            id="drop-down-menudesktop"
                          ></i>
                        </Link>
                        <i
                          className={
                            sub.children.length > 0 ? "fa fa-angle-down" : ""
                          }
                          id="drop-down-menumobi"
                        ></i>
                      </div>

                      <div className="dropdown-menu">
                        <ul>
                          {sub.children.length > 0 &&
                            sub.children.map((multisub, index) => (
                              <>
                                <li key={index} className="nav-item dropdown">
                                  <div className="second-submenu-div">
                                    <Link
                                      className="nav-link dropdown-toggle"
                                      onClick={tohidesidebar}
                                      title={multisub?.title}
                                      to={"/" + multisub?.url}
                                      id="submenu-item"
                                    >
                                      {multisub?.title}
                                      <i
                                        className={
                                          multisub.children.length > 0
                                            ? "fa fa-angle-down"
                                            : ""
                                        }
                                        id="drop-down-menudesksub"
                                      ></i>
                                    </Link>
                                    <i
                                      className={
                                        multisub.children.length > 0
                                          ? "fa fa-angle-down"
                                          : ""
                                      }
                                      id="drop-down-menumobisub"
                                    ></i>
                                  </div>
                                  <div className="dropdown-menu-1">
                                    <ul>
                                      {multisub.children.length > 0 &&
                                        multisub.children.map(
                                          (level4, index) => (
                                            <li
                                              key={index}
                                              className="nav-item dropdown"
                                            >
                                              <Link
                                                to={"/" + level4?.url}
                                                className="child-submenu"
                                                onClick={tohidesidebar}
                                              >
                                                {level4?.title}
                                                <i
                                                  className={
                                                    level4.children.length > 0
                                                      ? "fa fa-angle-down"
                                                      : ""
                                                  }
                                                ></i>
                                              </Link>
                                              <div className="dropdown-menu">
                                                <ul>
                                                  {level4.children.length > 0 &&
                                                    level4.children.map(
                                                      (child, index) => (
                                                        <li key={index}>
                                                          <Link
                                                            to={
                                                              "/" + child?.url
                                                            }
                                                          >
                                                            {child?.title}
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                </ul>
                                              </div>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </li>
                              </>
                            ))}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubMenu;
