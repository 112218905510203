import React from 'react';
import { motion } from 'framer-motion';
import styles from './WhyInternalAudit2.module.css';

const WhyInternalAudit = () => {
    const benefits = [
        {
            id: 1,
            title:"Tailored Setup and Integration:",
            text: " Align Zoho Campaigns with your goals and existing platforms seamlessly.",
            delay: 0.2
        },
        {
            id: 2,
            title:"End-to-End Campaign Management:",
            text: " Handle everything from content creation to campaign deployment.",
            delay: 0.4
        },
        {
            id: 3,
            title:"Data-Driven Optimization:",
            text: " Provide detailed reports and insights to enhance campaign performance.",
            delay: 0.6
        },
        {
            id: 4,
            title:"Training and Support:",
            text: " Equip your team with training and ongoing assistance for success.",
            delay: 0.8
        },
        {
            id: 5,
            title:"GDPR Compliance Guidance:",
            text: " Ensure adherence to privacy standards and build audience trust.",
            delay: 1
        },
        {
            id: 5,
            title:"Prioritize Compliance and Security:",
            text: " Stay GDPR-compliant and safeguard subscriber data with robust features.",
            delay: 1
        }
    ];

    return (
        <section className={styles.benefitsSection}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
             
                    <div className={styles.textContent}>
                        <div 
                            className={styles.headingContainer}
                            // initial={{ opacity: 0, y: 20 }}
                            // whileInView={{ opacity: 1, y: 0 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6 }}
                        >
                            <h2 className={styles.heading}>
                            Our Expert Zoho Campaign Services
                            </h2>
                    <p className={styles.subtitle}>
                    Simplify email marketing with powerful tools, automation, and seamless integrations.
                    </p>
                        </div>

                        <div
                            className={styles.benefitsList}
                            // initial={{ opacity: 0 }}
                            // whileInView={{ opacity: 1 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                    // initial={{ opacity: 0, x: -20 }}
                                    // whileInView={{ opacity: 1, x: 0 }}
                                    // viewport={{ once: true }}
                                    // transition={{ delay: benefit.delay }}
                                >
                                 <div className={styles.checkmark}>✓</div>
                                    <h1 className={styles.subHeading}>
                                    {benefit.title}
                                    <span className={styles.text}>{benefit.text}</span>
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div 
                        className={styles.imageSection}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                    >
                        <img 
                            className={styles.image}
                            src="https://elevateauditing.com/wp-content/uploads/2024/11/our-expert.jpg"
                            alt="Internal Audit Benefits"
                            loading="lazy"
                        />
                    </div>

                   
                </div>
            </div>
        </section>
    );
};

export default WhyInternalAudit;
