import React from 'react';
import { motion } from 'framer-motion';
import styles from './WhyInternalAudit.module.css';

const OneZoho  = () => {
    const benefits = [
        {
            id: 1,
            title: "Consultation & Customization:",
            text: " We understand your business needs and tailor Zoho One to fit your requirements.",
            delay: 0.2
        },
        {
            id: 2,
            title: "Implementation & Integration:",
            text: " Our team ensures seamless setup and integration with your existing systems.",
            delay: 0.4
        },
        {
            id: 3,
            title: "Training & Support:",
            text: " We provide training and ongoing support to ensure your team excels with Zoho One.",
            delay: 0.6
        },
        {
            id: 4,
            title: "Optimization & Expansion:",
            text: " We help scale and optimize Zoho One as your business grows.",
            delay: 0.8
        }
    ];

    return (
        <section style={{paddingTop:"0px"}} className={styles.benefitsSection}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    
                <div 
                        className={styles.imageSection}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                    >
                        <img 
                            className={styles.image}
                            src="https://elevateauditing.com/wp-content/uploads/2024/11/adgm-1-600x446-1.jpg"
                            alt="Internal Audit Benefits"
                            loading="lazy"
                        />
                    </div>
                    <div className={styles.textContent}>
                        <div 
                            className={styles.headingContainer}
                            // initial={{ opacity: 0, y: 20 }}
                            // whileInView={{ opacity: 1, y: 0 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6 }}
                        >
                            <h2 className={styles.heading}>
                            Our Zoho One Expertise
                            </h2>
                            <p style={{fontSize:"16px",color:"#333",fontWeight:"400",lineHeight:"26px",}}>
                            As your trusted Zoho Partner in Dubai, we offer expert services to help you leverage Zoho One:
                            </p>
                    {/* <p className={styles.subtitle}>
                    Simplify email marketing with powerful tools, automation, and seamless integrations.
                    </p> */}
                        </div>

                        <div style={{}}
                            className={styles.benefitsList}
                            // initial={{ opacity: 0 }}
                            // whileInView={{ opacity: 1 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                    // initial={{ opacity: 0, x: -20 }}
                                    // whileInView={{ opacity: 1, x: 0 }}
                                    // viewport={{ once: true }}
                                    // transition={{ delay: benefit.delay }}
                                >
                               <div className={styles.checkmark}>✓</div>

                                    <h1 className={styles.subHeading}>
                                    {benefit.title}
                                    <span className={styles.text}>{benefit.text}</span>
                                    </h1>
                                </div>
                            ))}
                        </div>
                        <button   onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.button}>
                            Book Your Free Consultation
                        </button>
                    </div>
                   
                </div>
            </div>
        </section>
    );
};

export default OneZoho;
