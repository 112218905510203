import WhyInternalAudit2 from "./whyInternalAudit2";
import WhyInternalAudit from "./whyInternalAudit";
import TakeYour from "./TakeYour";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ExpertAuditServices from './ExpertAuditServices'
import Botton from "./Bottom";
import AllPageHero from "../../../AllPageHero";
import FreeConsultation from "../../../Apurv/FooterContact";
import Footer from "../../../Footer";
import { useLocation } from "react-router-dom";
import SEO from "../../../../SEO/SEO"
const ZohoCampaigns = () => {
  const location = useLocation();
  const currPath = location.state?.currPath || [];
  const [scrollDirection, setScrollDirection] = useState("down");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollDirection("up");
      } else {
        setScrollDirection("down");
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, []);
  return (
    <>
    <SEO title={"Zoho Campaigns"} description={"Take your email marketing to the next level with Zoho Campaigns! Elevate Accounting & Auditing offers expert setup, automation, and optimization services in Dubai, UAE. Drive engagement, streamline workflows, and boost results with our tailored solutions."} keywords={"Zoho Campaigns Dubai, email marketing Dubai, Zoho email automation, email campaign management UAE, Zoho CRM integration, GDPR-compliant email marketing, Elevate Accounting & Auditing, email marketing services UAE, business email solutions, Zoho partner Dubai."} url={"https://elevatebpm.com/zoho-campaigns"}/>
      <AllPageHero currPath={currPath} headingName={"Zoho Campaigns"} />
      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >

<TakeYour />
      </motion.div>
      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }}
         // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >

        <WhyInternalAudit />
      </motion.div>

      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <WhyInternalAudit2 />
      </motion.div>
      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <ExpertAuditServices />
      </motion.div>
      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <Botton />
      </motion.div>
    





      <FreeConsultation />
      <Footer />
    </>

  );
};

export default ZohoCampaigns;
