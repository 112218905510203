import React from 'react';
import styles from './BooksServices.module.css';

const BooksServices = () => {
    const services = [
        {
            id: 1,
            title: "Consultation & Setup"
        },
        {
            id: 2,
            title: "Customization & Integration"
        },
        {
            id: 3,
            title: "Training & Support"
        },
        {
            id: 4,
            title: "Migration Assistance"
        }
    ];

    return (
        <section className={styles.servicesSection}>
            <div className={styles.decorativeCircle}></div>
            <div className={styles.decorativeDiagonalLines}></div>
            <div className={styles.decorativeCircleSmall}></div>
            <div className={styles.servicesContainer}>
                <h2 className={styles.servicesTitle}>
                    {/* <span className={styles.darkText}> </span> */}
                    <span className={styles.redText}>Our Zoho Books Services</span>
                </h2>
                
                <div className={styles.contentWrapper}>
                    <div className={styles.imageColumn}>
                        <img 
                            src="https://elevateauditing.com/wp-content/uploads/2024/11/why-choose-2.jpg" 
                            alt="Zoho Books Experts" 
                            className={styles.serviceImage}
                        />
                    </div>
                    
                    <div className={styles.textColumn}>
                        <h3 className={styles.subheading}>Expert Solutions for Your Accounting Needs</h3>
                        
                        <p className={styles.description}>
                            As an <strong>Authorized Zoho Partner</strong> in Dubai, Elevate Accounting & Auditing offers a 
                            range of expert services to help you make the most of Zoho Books:
                        </p>
                        
                        <div className={styles.servicesList}>
                            {services.map((service) => (
                                <div key={service.id} className={styles.serviceItem}>
                                <div className={styles.checkmark}>✓</div>
                                    <span className={styles.serviceTitle}>{service.title}</span>
                                </div>
                            ))}
                        </div>
                        <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.demoButton}>Start Automating Your Finances</button>
                         
                            
                         
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BooksServices;
