import styles from "./Elevate.module.css"

const Elevate = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.overlay}>
        <h1 className={styles.title}>Ready to Elevate Your Business with Zoho?</h1>
        <p className={styles.text}>
        Transform your business operations with Elevate Accounting & Auditing – your trusted Zoho Authorized Partner in Dubai. We’ll help you unlock the full potential of Zoho’s suite of applications, from Zoho CRM to Zoho Finance Solutions.
        </p>
        <p className={styles.text}>Contact us today to take the next step in your Zoho journey.</p>
      </div>
    </div>
  );
};

export default Elevate;