import React from "react";
import  { useState } from "react";
import Footer from "../Footer";  
import AllPageHero2 from "../AllPageHero2";
import FreeConsultation from "../Apurv/FooterContact";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import BusinessGrowth from "./businessgrowth";
import GetStarted from "./ZohoBook";
import BooksServices from "./BooksServices";
import ZohoFeatures from "./booksFeatuers";
import SEO from "../../SEO/SEO";


import styles from "./books.module.css";

const Books = () => {
   
  const location = useLocation();
  const currPath = location.state?.currPath || [];
  const [scrollDirection, setScrollDirection] = useState("down");

  return (
    <div>
      <SEO title="Zoho Books | Elevate Accounting & Auditing" description="Elevate Accounting & Auditing is your trusted partner in Dubai for Zoho Books, the leading accounting software for growing businesses. We help businesses streamline their financial processes and drive business success with Zoho Books." keywords="Zoho Books, accounting software, financial management, business growth, Dubai" url="https://elevatebpm.com/zoho-books/" />
     <AllPageHero2 currPath={currPath} headingName={"Zoho Books"} />
      <div className={styles.container}>
        <div className={styles.backgroundElements}>
          <div className={styles.diagonalPattern}></div>
          <div className={styles.circles}>
            <div className={styles.circle1}></div>
            <div className={styles.circle2}></div>
            <div className={styles.circle3}></div>
          </div>
          <div className={styles.dotsPattern}></div>
        </div>
        
        <div className={styles.heroSection}>
          <h1>
            <span className={styles.normalText}>Your Trusted Zoho Partner in Dubai</span> 
            <span className={styles.highlightText}>   UAE</span>
          </h1>
          
          <p className={styles.description}>
            At Elevate Accounting & Auditing, we help businesses of all sizes take control of their finances with 
            <strong> Zoho Books</strong>, the most comprehensive accounting software for growing businesses. 
            Whether you are managing invoices, payroll, or GST compliance, Zoho Books provides the tools 
            you need to streamline your financial processes and drive business success.
          </p>
          
          <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.demoButton}>Book a FREE consultation</button>
        </div>
      </div>

      <BusinessGrowth />
      <ZohoFeatures />
      <BooksServices /> 
      <GetStarted />
      <FreeConsultation />
      <Footer />
    </div>
  );
};

export default Books;
