import WhyInternalAudit2 from "./whyInternalAudit2";
// import WhyInternalAudit from "./whyInternalAudit";
// import TakeYour from "./TakeYour";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
// import ExpertAuditServices from './ExpertAuditServices'
import Botton from "./Bottom";
import AllPageHero from "../../../AllPageHero";
import FreeConsultation from "../../../Apurv/FooterContact";
import Footer from "../../../Footer";
import { useLocation } from "react-router-dom";
import ZohoOneComp from "./ZohoOneComp";
import OurZoho from "./OurZohoCrm";
import WhyChooseCon from './WhyChooseCon'
import SEO from "../../../../SEO/SEO"
const ZohoCampaigns = () => {
  const location = useLocation();
  const currPath = location.state?.currPath || [];
  const [scrollDirection, setScrollDirection] = useState("down");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollDirection("up");
      } else {
        setScrollDirection("down");
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, []);
  return (
    <>
     <SEO title={"Zoho CRM Consulting"} description={"Optimize your business operations with Zoho CRM consulting from Elevate Accounting & Auditing, your trusted Zoho Partner in Dubai, UAE. Automate workflows, enhance customer relationships, and drive business growth with expert CRM solutions. Book a free consultation today!"} keywords={"Zoho CRM Dubai, CRM consulting UAE, Zoho CRM implementation, Zoho-certified consultants, business automation UAE, customer relationship management Dubai, Zoho CRM integration, sales automation UAE, Elevate Accounting & Auditing, Zoho CRM support Dubai."} url={"https://elevatebpm.com/zoho-crm-consulting"}/>
    <AllPageHero currPath={currPath} headingName={"Zoho CRM Consulting"} />
    <motion.div
      initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ amount: 0.009 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >

<ZohoOneComp />
    </motion.div>

    <motion.div
      initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }}
        // From bottom when scrolling down, from top when scrolling up
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ amount: 0.009 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >

      {/* <WhyInternalAudit /> */}
    </motion.div>

    <motion.div
      initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ amount: 0.009 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <WhyInternalAudit2 />
    </motion.div>
    <motion.div
      initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ amount: 0.009 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      {/* <ExpertAuditServices /> */}
   
    

      <OurZoho/>
     

   
    
      </motion.div>
      <motion.div
      initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ amount: 0.009 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      {/* <ExpertAuditServices /> */}
   
    


     
      <WhyChooseCon/>
   
    
      </motion.div>
      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <Botton />
      </motion.div>
      <FreeConsultation />
      <Footer />
    </>

  );
};

export default ZohoCampaigns;
