import axios from "axios";


const instance = axios.create({
  baseURL: "https://elevatebpm.com/admin/api/v2",
  // baseURL: process.env.REACT_APP_API_URL,
  
});

export default instance;
