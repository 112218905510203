import React from "react";
import styles from "./ZohoSolutions.module.css"

const ZohoBenefits = () => {
  const benefits = [
    {
      number: "01",
      title: "Boost Your Business Efficiency",
      description: "Zoho automates manual tasks, integrates workflows, and removes redundancies, allowing your team to focus on strategic activities, improving overall operational efficiency."
    },
    {
      number: "02",
      title: "Maximize Productivity",
      description: "Zoho tools enhance collaboration, task management, and project tracking, empowering employees to work more effectively and achieve their goals faster."
    },
    {
      number: "03",
      title: "Improve Customer Relationships",
      description: "As your Zoho CRM consultants in UAE, we help you implement Zoho CRM to track leads, manage interactions, and improve service, which leads to better relationships and increased sales."
    },
    {
      number: "04",
      title: "Reduce Operational Costs",
      description: "Zoho's affordable subscription models replace expensive standalone software, helping to streamline operations and reduce overheads."
    },
    {
      number: "05",
      title: "Make Data-Driven Decisions",
      description: "Zoho's analytics tools provide real-time insights, allowing you to make informed, data-driven decisions and steer your business toward success."
    }
  ];

  return (
    <section className={styles.benefitsSection}>
      <div className={styles.backgroundElements}>
        <div className={styles.diagonalGrid}></div>
        <div className={styles.circleElements}>
          <div className={styles.circle1}></div>
          <div className={styles.circle2}></div>
          <div className={styles.circle3}></div>
        </div>
        <div className={styles.dotPattern}></div>
      </div>
      
      <div className={styles.benefitsContainer}>
        <div className={styles.imageColumn}>
          <img 
            src="https://elevateauditing.com/wp-content/uploads/2024/11/benefits-accounting.jpg" 
            alt="Business professionals analyzing data" 
            className={styles.benefitsImage}
          />
        </div>
        
        <div className={styles.contentColumn}>
          <h2 className={styles.benefitsHeading}>
            The Benefits of  <span className={styles.redText}>Zoho Solutions with </span>
            <br />
            <span className={styles.redText1}>Elevate Accounting & Auditing</span>
          </h2>
          
          <div className={styles.benefitsList}>
            {benefits.map((benefit) => (
              <div key={benefit.number} className={styles.benefitItem}>
                <div className={styles.benefitNumber}>
                  <span>{benefit.number}</span>
                </div>
                <div className={styles.benefitContent}>
                  <h3 className={styles.benefitTitle}>{benefit.title}</h3>
                  <p className={styles.benefitDescription}>{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
          
          <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.ctaButton}>
            Get in Touch to Explore Zoho Apps
          </button>
        </div>
      </div>
    </section>
  );
};

export default ZohoBenefits;