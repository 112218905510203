import React from "react";
import { motion } from "framer-motion";
import './ExpertAuditServices.css';

const services = [
  {
    title: "Expert team of auditors",
    description: "Team Elevate Auditing delivers independent, objective and high-quality auditing services in UAE. We have the best of the professionals in the industry whose vast knowledge and exposure in this field gives our clients a valuable insight into their state of affairs.",
    number: "01",
  },
  {
    title: "Time bound Audits",
    description: "As much as we give importance to deliver the finest and utmost comprehensive audits, we also equally value your time. We always make sure our service is delivered well on time as per the initial agreement.",
    number: "02",
  },
  {
    title: "Compliance with the industry best practices and standards",
    description: "Our audit procedures align with the best practices accepted in the industry and we adhere to the international standards of accounting and auditing.",
    number: "03",
  },
  {
    title: "Affordable Professional Charges",
    description: "We always make sure the audits we deliver surpass the price we charge. Providing top-notch services at the most affordable prices has always been our priority.",
    number: "04",
  },
  {
    title: "Comprehensive Audit Reports",
    description: "We provide detailed and comprehensive audit reports that give you a clear understanding of your financial status and areas that need improvement.",
    number: "05",
  },
  {
    title: "Client-Centric Approach",
    description: "Our services are tailored to meet the specific needs of each client, ensuring personalized and effective audit solutions.",
    number: "06",
  },
];

const ExpertAuditServices = () => {
  return (
    <motion.div 
      className="expert-audit-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className="heading">Why ELEVATE FIRST ACCOUNTING & AUDITING?</h2>
      <div className="main-container">
        <div className="image-container">
          <img
            src="https://www.shutterstock.com/image-photo/young-smiling-successful-professional-leader-600nw-2192831111.jpg"
            alt="Audit Team"
          />
        </div>
        <motion.div 
          className="services-list"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          {services.map((service) => (
            <motion.div 
              key={service.number} 
              className="service-item"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="number-circle">{service.number}</div>
              <div className="service-text">
                <h3 className="elementor-image-box-title">{service.title}</h3>
                <p className="elementor-image-box-description">{service.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ExpertAuditServices;