import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";


import { Player, Controls } from '@lottiefiles/react-lottie-player';
import styles from './AllPageHero.module.css';
import ContactUs from './Nirmal/ContactUs';
import { useEffect, useState } from 'react';
import { motion } from "framer-motion";
const AllPageHero = ({ currPath, headingName }) => {

  const navigate = useNavigate(); // React Router navigation function
  const location = useLocation();
  const path = location.pathname.substring(1).replace(/-/g, ' ').toLocaleUpperCase();
  const [scrollDirection, setScrollDirection] = useState("down");
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollDirection("up");
      } else {
        setScrollDirection("down");
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, []);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  // const capitalizedPath = capitalizeWords(path.toLowerCase());

  const capitalizedPath = headingName;
  // console.log("sfadfsafas",capitalizedPath)



  return (

    <div>
      <motion.div
        initial={{ opacity: scrollDirection === "down" ? 0 : 1, y: scrollDirection === "down" ? 100 : 0 }} // From bottom when scrolling down, from top when scrolling up
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.009 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <div className={styles.main}>
          <div className={styles.container}>
            <div style={{zIndex:"10000000"}} className="txt">
              <h1>{capitalizedPath}</h1>
              <div className={styles.allHeroNavigationLink} style={{ marginLeft: '1px', display: "flex", flexWrap: 'wrap' }}>
                {currPath.map((item, key) => {
                  console.log(item.name);
                  const isDisabled = item.url === "#" || key === currPath.length - 1;
                  const absoluteUrl = item.url.startsWith("/") ? item.url : `/${item.url}`; // Ensure absolute path

                  return (
                    <React.Fragment key={key}>
                      <Link
                        state={{ currPath: currPath.slice(0, key + 1) }} // Pass sliced array
                        to={isDisabled ? "#" : absoluteUrl}
                        style={{
                          color: isDisabled ? "rgba(255, 255, 255, 0.5)" : "rgb(255, 255, 255)",
                          fontFamily: "Poppins, sans-serif",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "26px",
                          pointerEvents: isDisabled ? "none" : "auto",
                          cursor: isDisabled ? "default" : "pointer"
                        }}
                        onClick={(e) => {
                          if (isDisabled) {
                            e.preventDefault();
                          } else {
                            e.preventDefault(); // Prevent default link behavior
                            navigate(absoluteUrl, {
                              replace: true,
                              state: { currPath: currPath.slice(0, key + 1) }, // Ensure correct sliced array in navigation
                            });
                          }
                        }}
                      >
                        {item.name}
                      </Link>
                      {key !== currPath.length - 1 && <span style={{ color: "rgba(255, 255, 255, 0.5)", margin: "0 5px" }}>:</span>}
                    </React.Fragment>
                  );
                })}
              </div>




            </div>
            <div className={styles.waveContainer}>
        <Player
          autoplay
          loop
          src="./animation_files/wave3.json"
          style={{ height: "400px", width: "100%" }}
        >
          <Controls
            visible={false}
            buttons={["play", "repeat", "frame", "debug"]}
          />
        </Player>
        </div>
            <div className={styles.FloatContact}>
              <ContactUs />
            </div>
          </div>
        </div>





        <div className={styles.blank}>

        </div>
      </motion.div>
    </div>
  );
}

export default AllPageHero;