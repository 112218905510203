import React from 'react';
import styles from './booksFeatuers.module.css';

const ZohoFeatures = () => {
    const features = [
        {
            id: 1,
            title: "Automation for Time Savings:",
            text: "Automate reminders and recurring tasks to save time."
        },
        {
            id: 2,
            title: "Complete Customization:",
            text: "Tailor templates, fields, and reports to your needs."
        },
        {
            id: 3,
            title: "Receivables Management:",
            text: "Create invoices, automate reminders, and offer online payments."
        },
        {
            id: 4,
            title: "Payables Management:",
            text: "Track bills, manage recurring expenses, and make payments easily."
        },
        {
            id: 5,
            title: "Inventory Tracking",
            text: "Automatically update inventory and set stock alerts."
        },
        {
            id: 6,
            title: "Project Management",
            text: "Send quotes, track payments, and bill project expenses."
        }
    ];

    return (
        <section className={styles.featuresSection}>
            <div className={styles.featuresContainer}>
                <div className={styles.leftColumn}>
                    <h2 className={styles.featuresTitle}>
                        <span className={styles.darkText}>Zoho Books Features that Drive </span>
 <span className={styles.redText}>Efficiency</span>
                    </h2>
                    <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.demoButton}>See Zoho Books in Action</button>
                </div>

                <div className={styles.rightColumn}>
                    {features.map((feature) => (
                        <div key={feature.id} className={styles.featureItem}>
                          <div className={styles.checkmark}>✓</div>
                            {/* <div className={styles.featureContent}>
                                <span className={styles.featureTitle}>{feature.title}:</span>
                                <span className={styles.featureText}> {feature.text}</span>
                            </div> */}


                            <div className={styles.featureContent}>
                                    <strong className={styles.featureTitle}>{feature.title}</strong>
                                    <span className={styles.featureText}>{feature.text}</span>
                                </div>



                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ZohoFeatures;
