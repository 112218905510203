import React from 'react';
import { motion } from 'framer-motion';
import styles from './WhyInternalAudit2.module.css';

const WhyInternalAudit = () => {
    const benefits = [
        {
            id: 1,
            title:"Centralize Customer Data:-",
            text: " Access all your customer information from a single platform, allowing your team to engage with clients more efficiently and make informed decisions.",
            delay: 0.2
        },
        {
            id: 2,
            title:"Automate Key Processes:-",
            text: " Automate repetitive tasks such as follow-up emails, lead assignments, and notifications, freeing up time to focus on revenue-generating activities.",
            delay: 0.4
        },
        {
            id: 3,
            title:"Gain Actionable Insights:-",
            text: " Use advanced reporting and analytics tools to uncover trends, optimize your sales pipeline, and understand customer behavior.",
            delay: 0.6
        },
        {
            id: 4,
            title:"Enhance Customer Engagement:-",
            text: " Offer personalized customer service through features like live chat, ticketing, and social media integrations.",
            delay: 0.8
        },
        {
            id: 5,
            title:"Scalability:-",
            text: " As your business grows, Zoho CRM grows with you, providing scalable solutions to meet your ever-evolving needs.",
            delay: 1
        },
 
    ];

    return (
        <section className={styles.benefitsSection}>
            <div className={styles.container}>
            <h2 className={styles.heading}>
            Zoho CRM: A Complete Solution for Your Business Needs
                            </h2>
                    <p className={styles.subtitle}>
                    Zoho CRM is a versatile and robust platform that enables businesses to manage their sales, marketing, customer support, and more.
                    </p>
                <div className={styles.contentWrapper}>

             
                    <div className={styles.textContent}>
                        <div 
                            className={styles.headingContainer}
                            // initial={{ opacity: 0, y: 20 }}
                            // whileInView={{ opacity: 1, y: 0 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6 }}
                        >
                      
                        </div>

                        <div
                            className={styles.benefitsList}
                            // initial={{ opacity: 0 }}
                            // whileInView={{ opacity: 1 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                    // initial={{ opacity: 0, x: -20 }}
                                    // whileInView={{ opacity: 1, x: 0 }}
                                    // viewport={{ once: true }}
                                    // transition={{ delay: benefit.delay }}
                                >
                                    <div className={styles.checkmark}>✓</div>
                                    <h1 className={styles.subHeading}>
                                    {benefit.title}
                                    <span className={styles.text}>{benefit.text}</span>
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div 
                        className={styles.imageSection}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                    >
                        <img 
                            className={styles.image}
                            src="https://elevateauditing.com/wp-content/uploads/2024/11/zoho-crm-a-complete-solution-for-your-business-needs-1536x1025.jpg"
                            alt="Internal Audit Benefits"
                            loading="lazy"
                        />
                    </div>

                        <button
                               className={styles.button}
                               onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                               
                             >
                               Get Started Today with Zoho CRM
                               
                             </button>
                </div>
            </div>
        </section>
    );
};

export default WhyInternalAudit;
