import React from "react";
import { motion } from "framer-motion";
import styles from "./OurZoho.module.css";

const OurZoho = () => {
  const benefits = [
    {
      id: 1,
      title: "Consultation & Implementation:",
      text: "We'll assess your HR requirements and implement Zoho People to best support your goals.",
      delay: 0.2,
    },
    {
      id: 2,
      title: "Customization & Integration:",
      text: "Tailor Zoho People to your processes and integrate with existing systems for a seamless experience.",
      delay: 0.4,
    },
    {
      id: 3,
      title: "Training & Support:",
      text: "Provide ongoing training and support to ensure your HR team can use Zoho People effectively.",
      delay: 0.6,
    },
    {
      id: 4,
      title: "HR Optimization:",
      text: "Help you scale and optimize Zoho People as your business and HR needs evolve.",
      delay: 0.8,
    },
  ];

  return (
    <section className={styles.servicesSection}>
      {/* Elementos decorativos - NUEVO */}
      <div className={styles.decorativeElements}>
        <div className={styles.topLeftCircle}></div>
        <div className={styles.bottomRightCircle}></div>
        <div className={styles.diagonalPattern}></div>
        <div className={styles.dotsGrid}></div>
      </div>
      
      <div>
        <h2 className={styles.heading}>
          Our  <span className={styles.highlight}> Zoho Projects Services</span>
        </h2>
        <p className={styles.subtitle}>
          As your trusted Zoho partner in the UAE, Elevate Accounting & Auditing offers a range of services to ensure you get the most out of Zoho Projects:
        </p>
      </div>
      
      <div className={styles.container}>
        {/* Left Side (Text Content) */}
        <div className={styles.textContent}>
          <div className={styles.benefitsList}>
            {benefits?.map((benefit) => (
              <motion.div
                key={benefit.id}
                className={styles.benefitItem}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: benefit.delay }}
              >
              <div className={styles.checkmark}>✓</div>

                <div>
                  <h3 className={styles.subHeading}>{benefit.title}</h3>
                  <p className={styles.text}>{benefit.text}</p>
                </div>
              </motion.div>
            ))}
          </div>

          <button
            className={styles.button}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Get Expert Support
          </button>
        </div>

        {/* Right Side (Image) */}
        <motion.div
          className={styles.imageSection}
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          <img
            className={styles.image}
            src="https://elevateauditing.com/wp-content/uploads/2024/12/Business-Strategy-why.png"
            alt="Zoho Projects Services"
            loading="lazy"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default OurZoho;