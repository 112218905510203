import React, { useState } from "react";
import axios from "axios";
import styles from "./ContactUsStyles.module.css";
import { TfiAngleDown } from "react-icons/tfi";

const ContactUs = () => {
    const [selectedService, setSelectedService] = useState("");
    const [formData, setFormData] = useState({
        company: "",
        name: "",
        email: "",
        mobile: "",
        country: ""
    });
    const [buttonText, setButtonText] = useState("Request A Callback Now");

    const serviceOptions = [
        "Auditing",
        "Accounting & VAT",
        "VAT",
        "Corporate Tax Services",
        "Company Formation",
        "CFO Service",
        "Business Valuation",
        "Payroll & HR Services",
        "Golden Visa",
        "AML & Compliance",
        "Digital Marketing",
        "Corporate Services",
        "ICV Certification Service",
        "Liquidation",
        "ESR & UBO Services",
        "Business Collaboration",
        "Tax Residency Certificate",
        "Account Opening Assistance",
        "Bank Account Opening Assistance"
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async () => {
        setButtonText("Sending...");
        try {
            const response = await axios.post("https://elevatebpm.com/admin/api/v2/getInTouchStore", {
                company_name: formData.company,
                full_name: formData.name,
                email: formData.email,
                mobile: formData.mobile,
                country: formData.country,
                enquiry_type: selectedService || "Consulting"
            });
                setButtonText("Request Sent");
                setFormData({
                    company: "",
                    name: "",
                    email: "",
                    mobile: "",
                    country: ""
                });
                setSelectedService("");
                console.log(response.data);
                
        } catch (error) {
            setButtonText("Request A Callback Now");
            console.error("Error:", error);
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>Let's Talk</h2>
            <p className={styles.subHeading}>Free Consultation</p>

            <input
                type="text"
                className={styles.input}
                placeholder="Company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
            />
            <input
                type="text"
                className={styles.input}
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
            />
            <input
                type="email"
                className={styles.input}
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
            />
            <input
                type="tel"
                className={styles.input}
                placeholder="Mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                maxLength={10}
            />
            <input
                type="text"
                className={styles.input}
                placeholder="Country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
            />

            {/* Dropdown */}
            <div className={styles.dropdownContainer}>
                <div className={styles.selectWrapper}>
                    <select
                        className={styles.select}
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                    >
                        <option value="">What Services Are You Looking</option>
                        {serviceOptions.map((service, index) => (
                            <option key={index} value={service}>
                                {service}
                            </option>
                        ))}
                    </select>
                    <TfiAngleDown className={styles.dropdownIcon} />
                </div>
            </div>

            {/* Submit Button */}
            <button className={styles.button} onClick={handleSubmit}>
                {buttonText}
            </button>
        </div>
    );
};

export default ContactUs;
