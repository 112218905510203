import React from 'react';
import { motion } from 'framer-motion';
import styles from './WhyInternalAudit.module.css';

const AllYour = () => {
    const benefits = [
        {
            id: 1,
            title: "Sales: Automate Your Sales Process:",
            text: " Automate your sales process with structured workflows to close deals faster and consistently across all channels.",
            delay: 0.2
        },
        {
            id: 2,
            title: "Marketing: Maximize Your Marketing ROI:",
            text: " Run targeted campaigns and automate email workflows to maximize your marketing ROI.",
            delay: 0.4
        },
        {
            id: 3,
            title: "Customer Service: Boost Customer Satisfaction:",
            text: " Provide fast, multi-channel support to resolve issues quickly and boost customer satisfaction.",
            delay: 0.6
        },
        {
            id: 4,
            title: "Finance: Maintain Financial Health:",
            text: " Track expenses, streamline billing, and ensure accurate financial reporting to maintain financial health.",
            delay: 0.8
        },
        {
            id: 5,
            title: "HR: Build a Strong Workforce:",
            text: " Simplify HR tasks like onboarding, attendance management, and performance tracking to build a strong workforce.",
            delay: 1.0
        },
        {
            id: 6,
            title: "Operations: Optimize Processes:",
            text: " Manage projects, orders, and inventory efficiently with real-time insights and optimized processes.",
            delay: 1.2
        },
        {
            id: 7,
            title: "Ecommerce: Create a Unified Experience:",
            text: " Create and manage your online store, integrating eCommerce with CRM and marketing tools for a unified experience.",
            delay: 1.4
        }
    ];

    return (
        <section className={styles.benefitsSection}>
            <div className={styles.container}>
                <div  className={`${styles.specialContentWrapp} ${styles.contentWrapper}`}>
                    
               
                    <div className={styles.textContent}>
                        <div 
                            className={styles.headingContainer}
                            // initial={{ opacity: 0, y: 20 }}
                            // whileInView={{ opacity: 1, y: 0 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6 }}
                        >
                            <h2 className={styles.heading}>
                            All Your Teams, Better Together
                            </h2>
                            <p style={{fontSize:"16px",color:"#333",fontWeight:"400",lineHeight:"26px",}}>
                            Zoho One brings together various departments to work cohesively, driving efficiency and success across your business.
                            </p>
                    {/* <p className={styles.subtitle}>
                    Simplify email marketing with powerful tools, automation, and seamless integrations.
                    </p> */}
                        </div>

                        <div
                            className={styles.benefitsList}
                            // initial={{ opacity: 0 }}
                            // whileInView={{ opacity: 1 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                    // initial={{ opacity: 0, x: -20 }}
                                    // whileInView={{ opacity: 1, x: 0 }}
                                    // viewport={{ once: true }}
                                    // transition={{ delay: benefit.delay }}
                                >
                               <div className={styles.checkmark}>✓</div>
                                    <h1 className={styles.subHeading}>
                                    {benefit.title}
                                    <span className={styles.text}>{benefit.text}</span>
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{marginRight:"20px"}}
                        className={styles.imageSection}
                       
                    >
                        <img 
                            className={styles.image}
                            src="https://elevateauditing.com/wp-content/uploads/2024/11/management-consultancy.jpg"
                            alt="Internal Audit Benefits"
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AllYour;
