import styles from "./Bottom.module.css";

const Botton = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.overlay}>
        <h1 className={styles.title}>Let’s Elevate Your Email Marketing Strategy</h1>
        <p className={styles.text}>
          Transform the way you engage with your audience using Zoho Campaigns. Partner with Elevate
          Accounting & Auditing, the trusted Zoho expert in Dubai, UAE, for personalized services that ensure
          success.
        </p>
        <p className={styles.text}>Contact us today to schedule a consultation and unlock the true potential of your email marketing efforts.</p>
      </div>
    </div>
  );
};

export default Botton;