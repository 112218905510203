"use client"

import React from "react"
 import styles from "./End-to-End.module.css"

const ZohoImplementationProcess = () => {
  const implementationSteps = [
    {
      title: "Discovery and Analysis",
      description: "We assess your business needs to determine the most suitable Zoho applications."
    },
    {
      title: "Planning and Strategy",
      description: "We create a clear implementation plan with timelines and milestones."
    },
    {
      title: "Customization and Configuration",
      description: "We configure Zoho to match your specific business requirements."
    },
    {
      title: "Data Migration",
      description: "We ensure secure and accurate transfer of your data into Zoho."
    },
    {
      title: "Training and Adoption",
      description: "We provide training to empower your team and ensure smooth adoption."
    },
    {
      title: "Integration and Automation",
      description: "We integrate Zoho with your existing systems and automate key processes."
    },
    {
      title: "Testing and Quality Assurance",
      description: "We rigorously test the system to ensure everything works as expected."
    },
    {
      title: "Go-Live and Deployment",
      description: "We facilitate a seamless launch with minimal disruption to operations."
    },
    {
      title: "Post-Implementation Support",
      description: "We provide ongoing support to optimize and scale your Zoho solution."
    }
  ]

  return (
    <section className={styles.implementationSection}>
      <div className={styles.container}>
        <h2 className={styles.heading}>
          Elevate's   <span className={styles.highlight}> End-to-End Zoho Implementation Process</span>
        </h2>
        
        <p className={styles.subheading}>
          As your <strong style={{fontFamily: "Roboto, sans-serif", fontWeight: "700",}}>Zoho Implementation Partners in Dubai</strong>, we ensure a seamless, customized approach to Zoho adoption:
        </p>
        
        <div className={styles.stepsContainer}>
          {implementationSteps.slice(0, 5).map((step, index) => (
            <div key={index} className={styles.stepItem}>
              <div className={styles.checkIcon}>
              <div className={styles.checkmark}>✓</div>
              </div>
              <div className={styles.stepContent}>
                <h3 className={styles.stepTitle}>{step.title}</h3>
                <p className={styles.stepDescription}>{step.description}</p>
              </div>
            </div>
          ))}
          
          {implementationSteps.slice(5).map((step, index) => (
            <div key={index + 5} className={styles.stepItem}>
             <div className={styles.checkmark}>✓</div>
              <div className={styles.stepContent}>
                <h3 className={styles.stepTitle}>{step.title}</h3>
                <p className={styles.stepDescription}>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
        
        <div className={styles.buttonContainer}>
          <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.ctaButton}>Talk to Us About Implementation</button>
        </div>
      </div>
    </section>
  )
}

export default ZohoImplementationProcess;