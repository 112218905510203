import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const freeZones = [
  {
    name: "Dubai Multi Commodities Centre (DMCC)."
  },
  {
    name: "Dubai World Central (Dubai South)(DWC)."
  },
  {
    name: "Jabel Ali Free Zone Authority(JAFZA)."
  },
  {
    name: "Ras Al Khaimah Economic Zone (RAKEZ)."
  },
  {
    name: "Hamriya Free Zone."
  },
  {
    name: "Sharjah Airport International Free Zone."
  }
];

const ApprovedAuditors = () => {
  const [screenSize, setScreenSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 1200,
    size: 'desktop'
  });

  useEffect(() => {
    const updateSize = () => {
      const width = window.innerWidth;
      let size = 'desktop';
      
      if (width < 480) size = 'mobile-small';
      else if (width < 768) size = 'mobile';
      else if (width < 992) size = 'tablet';
      else if (width < 1200) size = 'desktop-small';
      
      setScreenSize({ width, size });
    };
    
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // Responsive styles based on screen size
  const getResponsiveStyles = () => {
    const { size } = screenSize;
    const isMobile = size === 'mobile' || size === 'mobile-small';
    const isTablet = size === 'tablet';
    
    // Fluid typography calculation
    const getFluidFontSize = (min, max) => {
      if (size === 'mobile-small') return `${min}rem`;
      if (size === 'mobile') return `${min + 0.15}rem`;
      if (size === 'tablet') return `${min + 0.3}rem`;
      return `${max}rem`;
    };
    
    const getPadding = () => {
      if (size === 'mobile-small') return "1.5rem 1rem";
      if (size === 'mobile') return "2rem 1rem";
      if (size === 'tablet') return "3rem 1.5rem";
      if (size === 'desktop-small') return "3.5rem 1.5rem";
      return "4rem 1.5rem";
    };
    
    return {
      container: {
        backgroundColor: "#ffffff",
        padding: getPadding(),
        display: "flex",
        flexDirection: isMobile || isTablet ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "1200px",
        margin: "0 auto",
        gap: isMobile ? "2rem" : isTablet ? "3rem" : "0",
        overflow: "hidden"
      },
      contentSection: {
        flex: "1",
        order: isMobile || isTablet ? 2 : 1,
        width: "100%",
        padding: isMobile ? "0" : "0 1rem"
      },
      headingContainer: {
        marginBottom: isMobile ? "1.5rem" : isTablet ? "1.8rem" : "2rem",
        textAlign: isMobile || isTablet ? "center" : "left"
      },
      heading1: {
        fontSize: getFluidFontSize(1.3, 2),
        fontWeight: "bold",
        backgroundImage: "linear-gradient(to right, rgb(30, 43, 92), rgb(196, 54, 48))",
        WebkitBackgroundClip: "text",
        color: "transparent",
        marginBottom: "0.5rem",
        lineHeight: 1.2
      },
      heading2: {
        fontSize: getFluidFontSize(1.3, 2),
        fontWeight: "bold",
        color: "#C43630",
        marginBottom: isMobile ? "1.5rem" : isTablet ? "1.8rem" : "2rem",
        lineHeight: 1.2
      },
      listContainer: {
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        gap: size === 'mobile-small' ? "0.4rem" : "0.5rem",
        position: "relative",
        paddingLeft: isMobile || isTablet ? "0" : "0" // No padding when centered
      },
      listItem: {
        display: "flex",
        alignItems: isMobile || isTablet ? "center" : "flex-start",
        gap: "0.8rem",
        justifyContent: isMobile || isTablet ? "center" : "flex-start",
        transition: "transform 0.2s ease-in-out",
        position: "relative" // Important for alignment
      },
      checkCircle: {
        width: size === 'mobile-small' ? "20px" : "24px",
        height: size === 'mobile-small' ? "20px" : "24px",
        borderRadius: "50%",
        background: "linear-gradient(to right, #1E2B5C, #C43630)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        // position: isMobile || isTablet ? "relative" : "absolute", // Fixed position in desktop
        left: isMobile || isTablet ? "auto" : "0" // Aligned to the left in desktop
      },
      checkText: {
        color: "white",
        fontSize: size === 'mobile-small' ? "12px" : "14px"
      },
      zoneName: {
        fontSize: getFluidFontSize(0.85, 0.95),
        fontFamily: "Roboto, sans-serif",
        color: "#1F2B5C",
        
        padding: isMobile || isTablet ? "0px 0px 0px 5px" : "0px 0px 0px 35px", // Increased padding for alignment
        lineHeight: size === 'mobile-small' ? "30px" : "35px",
        fontWeight: 600,
        margin: 0,
        textAlign: isMobile || isTablet ? "'left'," : "left"
      },
      imageSection: {
        flex: isMobile ? "none" : isTablet ? "none" : "1",
        textAlign: isMobile || isTablet ? "center" : "right",
        order: isMobile || isTablet ? 1 : 2,
        marginBottom: isMobile ? "1rem" : isTablet ? "1.5rem" : 0,
        display: "flex",
        justifyContent: isMobile || isTablet ? "center" : "flex-end",
        alignItems: "center"
      },
      image: {
        maxWidth: "100%",
        height: "auto",
        width: isMobile ? "80%" : isTablet ? "70%" : "100%",
        borderRadius: "8px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease"
      }
    };
  };

  const styles = getResponsiveStyles();
  const { size } = screenSize;
  const isMobile = size === 'mobile' || size === 'mobile-small';
  const isTablet = size === 'tablet';

  return (
    <div style={styles.container}>
      <div style={styles.contentSection}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          style={styles.headingContainer}
        >
          <h2 style={styles.heading1}>
            We are approved auditors both in the
          </h2>
          <h2 style={styles.heading2}>
            mainland and in the following Free Zones:
          </h2>
        </motion.div>

        <div style={styles.listContainer}>
          {freeZones.map((zone, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              style={styles.listItem}
              whileHover={{ transform: "translateX(5px)" }}
            >
              {/* For mobile and tablet, keep circles inline */}
              {(isMobile || isTablet) && (
                <div style={styles.checkCircle}>
                  <span style={styles.checkText}>✓</span>
                </div>
              )}
              
              <p style={styles.zoneName}>
                {/* For desktop, place circles with absolute positioning */}
                {!(isMobile || isTablet) && (
                  <span style={{
                    position: "absolute",
                    left: "0",
                    top: "50%",
                    transform: "translateY(-50%)"
                  }}>
                    <div style={styles.checkCircle}>
                      <span style={styles.checkText}>✓</span>
                    </div>
                  </span>
                )}
                {zone.name}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
      
      <motion.div 
        style={styles.imageSection}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
        whileHover={{ scale: 1.02 }}
      >
        <img 
          src="https://elevateauditing.com/wp-content/uploads/2024/11/Role-of-a-Forensic-Auditor-1.png" 
          alt="Forensic Auditor Illustration" 
          style={styles.image}
        />
      </motion.div>
    </div>
  );
};

export default ApprovedAuditors;