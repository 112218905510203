
import styles from './Header.module.css'
import { IoMenuSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import NavBar from './NavBar'
import { useState, useEffect, useRef } from "react";
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BiPhoneCall } from "react-icons/bi";
import { TfiEmail } from "react-icons/tfi";
import { TiMessages } from "react-icons/ti";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import instance from "../../api/api_instance";
import toastr from "toastr";
// import { FaPhoneAlt } from "react-icons/bi";
const HeaderSuraj = () => {



    const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);
    const [menu, setMenu] = useState([]);

    const [menuData, setMenuData] = useState([


    ]);

    const transformData = (data, parentPath = [{ name: "Home", url: "/" }]) => {
        return data.map(item => {
            const currentPath = [...parentPath, { name: item.title, url: item.url }]; // Append new item
    
            return {
                id: item.id,
                name: item.title,
                link: item.url,
                parent_id: item.parent_id,
                order: item.order,
                dropDWon: true,
                toggle: false,
                currentPath, // Array of objects with name & url
                items: item.children ? transformData(item.children, currentPath) : []
            };
        });
    };
    
    
    

    const getAllMenu = async () => {
        await instance
            .post(`/getAllMenus`)
            .then((res) => {
                // setMenu(res.data);
                console.log(res.data.data)
             
                const frontendData = transformData(res.data.data)
       
                console.log(frontendData);
                setMenu(frontendData);
                setMenuData(frontendData)
                console.log("newData")
                console.log(frontendData)
            })
            .catch((error) => {
                toastr.error(error.message);
            });
    };

    useEffect(() => {
        getAllMenu();

    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1100);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [isFixed, setIsFixed] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const lastScrollY = useRef(0);





    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY <= 50) {
                setIsFixed(false); // Set header to static when at the top
            } else {
                setIsFixed(true);  // Make header fixed when scrolling down
                if (window.scrollY > lastScrollY.current) {
                    setIsHidden(true); // Hide header on scroll down
                } else {
                    setIsHidden(false); // Show header on scroll up
                }
            }
            lastScrollY.current = window.scrollY;
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);





    const toggleDropdown = (menuIndex, subIndex = null, subSubIndex = null, subSubSubIndex = null) => {
        setMenuData((prevMenu) =>
            prevMenu.map((menu, i) => {
                if (i === menuIndex) {
                    if (subIndex === null) {
                        return {
                            ...menu,
                            toggle: !menu.toggle,
                            items: !menu.toggle
                                ? (menu.items?.map((subMenu) => ({
                                    ...subMenu,
                                    toggle: false,
                                    items: (subMenu.items?.map((subSubMenu) => ({
                                        ...subSubMenu,
                                        toggle: false,
                                        items: (subSubMenu.items?.map((subSubSubMenu) => ({
                                            ...subSubSubMenu,
                                            toggle: false,
                                        })) || []),
                                    })) || []),
                                })) || [])
                                : (menu.items || []),
                        };
                    } else if (subSubIndex === null) {
                        return {
                            ...menu,
                            items: (menu.items?.map((subMenu, j) => {
                                if (j === subIndex) {
                                    return {
                                        ...subMenu,
                                        toggle: !subMenu.toggle,
                                        items: !subMenu.toggle
                                            ? (subMenu.items?.map((subSubMenu) => ({
                                                ...subSubMenu,
                                                toggle: false,
                                                items: (subSubMenu.items?.map((subSubSubMenu) => ({
                                                    ...subSubSubMenu,
                                                    toggle: false,
                                                })) || []),
                                            })) || [])
                                            : (subMenu.items || []),
                                    };
                                }
                                return { ...subMenu, toggle: false };
                            }) || []),
                        };
                    } else if (subSubSubIndex === null) {
                        return {
                            ...menu,
                            items: (menu.items?.map((subMenu, j) => {
                                if (j === subIndex) {
                                    return {
                                        ...subMenu,
                                        items: (subMenu.items?.map((subSubMenu, k) => {
                                            if (k === subSubIndex) {
                                                return {
                                                    ...subSubMenu,
                                                    toggle: !subSubMenu.toggle,
                                                    items: !subSubMenu.toggle
                                                        ? (subSubMenu.items?.map((subSubSubMenu) => ({
                                                            ...subSubSubMenu,
                                                            toggle: false,
                                                        })) || [])
                                                        : (subSubMenu.items || []),
                                                };
                                            }
                                            return { ...subSubMenu, toggle: false };
                                        }) || []),
                                    };
                                }
                                return { ...subMenu, toggle: false };
                            }) || []),
                        };
                    } else {
                        return {
                            ...menu,
                            items: (menu.items?.map((subMenu, j) => {
                                if (j === subIndex) {
                                    return {
                                        ...subMenu,
                                        items: (subMenu.items?.map((subSubMenu, k) => {
                                            if (k === subSubIndex) {
                                                return {
                                                    ...subSubMenu,
                                                    items: (subSubMenu.items?.map((subSubSubMenu, l) => {
                                                        if (l === subSubSubIndex) {
                                                            return {
                                                                ...subSubSubMenu,
                                                                toggle: !subSubSubMenu.toggle,
                                                            };
                                                        }
                                                        return { ...subSubSubMenu, toggle: false };
                                                    }) || []),
                                                };
                                            }
                                            return { ...subSubMenu, toggle: false };
                                        }) || []),
                                    };
                                }
                                return { ...subMenu, toggle: false };
                            }) || []),
                        };
                    }
                }
                return { ...menu, toggle: false };
            }) || []
        );
    };
    




    const [showMenu, setShowMenu] = useState(false);





    const menuRef = useRef(null);
    const menuButtonRef = useRef(null);


    const handleClickOutside = (event) => {
        if (menuRef && menuButtonRef &&
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            menuButtonRef.current &&
            !menuButtonRef.current.contains(event.target)
        ) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);




    return (
        <>
            <header className={`${styles.header} ${""} ${styles.static}`}>
                <div className={styles.header_container}>
                    <Link  to='/'><img className={styles.header_logo} src="https://elevatebpm.com/admin/assets/media/img/logo.png" alt="" /></Link>


                    {/* desktop menu */}


                    {!isMobile ? (<div className={`${styles.header_desktop} ${styles.special_case_header_desktop}`}
                    >
                        {<div className={styles.header_contact_container}><a href="tel:+971561672533" className={styles.contactItem}>
                            <BiPhoneCall className={styles.icon} />
                            <div className={styles.contact_info_div}>
                                <span className={styles.label}>CALL US :</span>
                                <span className={styles.info}>+971 56 167 2533</span>
                            </div>


                        </a>
                            <div className={styles.divider}></div>
                            <a href="mailto:info@elevateauditing.com" className={styles.contactItem}>
                                <TfiEmail className={styles.icon} />
                                <div className={styles.contact_info_div} >
                                    <span className={styles.label}>EMAIL :</span>
                                    <span className={styles.info}>info@elevateauditing.com</span>
                                </div>

                            </a>
                            <div className={styles.divider}></div>
                            <a href='#' className={styles.contactItem}>
                                <TiMessages className={styles.icon} />

                                <span className={styles.partner}>Become a Partner</span>
                            </a></div>}
                        <NavBar menuData={menuData} isFixed={isFixed}/>
                    </div>

                    )
                        : (<div>
                            <div className={styles.mobile_header}>
                                {!showMenu ? <IoMenuSharp ref={menuButtonRef} onClick={() => setShowMenu(!showMenu)} className={styles.mobile_header_icon} /> : null}

                            </div>
                        </div>



                        )}

                </div>



            </header>



            {isFixed && <header className={`${styles.header} ${isHidden ? styles.hide : ""} ${styles.fixed}`}>
                <div className={styles.header_container}>
                    <Link to='/'><img className={styles.header_logo} src="https://elevatebpm.com/admin/assets/media/img/logo.png" alt="" /></Link>


                    {/* desktop menu */}


                    {!isMobile ? (<div className={`${styles.header_desktop} ${!isFixed ? styles.special_case_header_desktop : ''}`}
                    >
                      
                        <NavBar menuData={menuData} isFixed={isFixed}/>
                    </div>

                    )
                        : (<div>
                            <div className={styles.mobile_header}>
                                {!showMenu ? <IoMenuSharp ref={menuButtonRef} onClick={() => setShowMenu(!showMenu)} className={styles.mobile_header_icon} /> : null}

                            </div>
                        </div>



                        )}

                </div>



            </header>}


            

            {/* mobile menu   */}
            {isMobile && showMenu && <div useRef={menuRef} className={styles.mobile_menu_container}>
                <RxCross1 onClick={() => setShowMenu(!showMenu)} className={styles.mobile_header_cross_icon} />
                <ul className={styles.mobile_menu_ul}>
                    {menuData.map((menu, menuIndex) => (
                        <>
                            <li className={styles.mobile_menu_li}>
                                <Link state={{ currPath: menu.currentPath }}
                                    className={styles.mobile_menu_link}
                                    onClick={(e) => {
                                        // e.preventDefault();
                                        setShowMenu(false);
                                    }}
                                    to={menu.link}
                                >
                                    <div>

                                        {menu.name}
                                    </div>

                                </Link>
                                {menu.items.length > 0 ? (
                                    menu.toggle ?
                                        <MdOutlineKeyboardArrowUp onClick={(e) => {
                                            // e.preventDefault();
                                            toggleDropdown(menuIndex);
                                        }} className={styles.mobile_menu_link_icon_drop_down} /> :
                                        <MdOutlineKeyboardArrowDown onClick={(e) => {
                                            // e.preventDefault();
                                            toggleDropdown(menuIndex);
                                        }} className={styles.mobile_menu_link_icon_drop_down} />
                                ) : null}
                            </li>


                            {menu.items.length > 0 && menu.toggle && (
                                <ul className={styles.mobile_menu_ul_ul}>
                                    {menu.items.map((subMenu, subIndex) => (

                                        <>
                                            <li><Link state={{ currPath: subMenu.currentPath }}
                                                className={styles.mobile_menu_link_link}
                                                onClick={(e) => {
                                                    // e.preventDefault();
                                                    setShowMenu(false);
                                                }}
                                                to={subMenu.link}
                                            >
                                                <div>
                                                    <MdOutlineKeyboardArrowRight className={styles.mobile_menu_link_icon} />
                                                    {subMenu.name}
                                                </div>

                                            </Link>
                                                {subMenu.items.length > 0 ? (
                                                    subMenu.toggle ?
                                                        <MdOutlineKeyboardArrowUp onClick={(e) => {

                                                            toggleDropdown(menuIndex, subIndex);
                                                        }} className={styles.mobile_menu_link_icon_drop_down} /> :
                                                        <MdOutlineKeyboardArrowDown onClick={(e) => {

                                                            toggleDropdown(menuIndex, subIndex);
                                                        }} className={styles.mobile_menu_link_icon_drop_down} />
                                                ) : null}</li>


                                            {subMenu.items.length > 0 && subMenu.toggle && (
                                                <ul className={styles.mobile_menu_ul_ul_ul}>
                                                    {subMenu.items.map((subSubMenu, subSubIndex) => (
                                                        <>
                                                            <li>
                                                                <Link state={{ currPath: subSubMenu.currentPath }}
                                                                    className={styles.mobile_menu_link_link_link}
                                                                    onClick={(e) => {
                                                                        // e.preventDefault();
                                                                        setShowMenu(false);
                                                                    }}
                                                                    to={subSubMenu.link}
                                                                >
                                                                    <div>
                                                                        <MdOutlineKeyboardArrowRight className={styles.mobile_menu_link_icon} />
                                                                        {subSubMenu.name}
                                                                    </div>

                                                                </Link>
                                                                {subSubMenu.items.length > 0 ? (
                                                                    subSubMenu.toggle ?
                                                                        <MdOutlineKeyboardArrowUp onClick={(e) => {

                                                                            toggleDropdown(menuIndex,subIndex, subSubIndex);
                                                                        }} className={styles.mobile_menu_link_icon_drop_down} /> :
                                                                       <MdOutlineKeyboardArrowDown onClick={(e) => {

                                                                            toggleDropdown(menuIndex, subIndex,subSubIndex);
                                                                        }} className={styles.mobile_menu_link_icon_drop_down} />
                                                                ) : null}
                                                            </li>
                                                            {subSubMenu.items.length > 0 && subSubMenu.toggle && (
                                                                <ul className={styles.mobile_menu_ul_ul_ul}>
                                                                    {subSubMenu.items.map((subSubSubMenu, subSubSubIndex) => (
                                                                        <>
                                                                            <li>
                                                                                <Link state={{ currPath: subSubSubMenu.currentPath }}
                                                                                    className={styles.mobile_menu_link_link_link_link}
                                                                                    onClick={(e) => {
                                                                                        // e.preventDefault();
                                                                                        setShowMenu(false);
                                                                                    }}
                                                                                    to={subSubSubMenu.link}
                                                                                >
                                                                                    <div>
                                                                                        <MdOutlineKeyboardArrowRight className={styles.mobile_menu_link_icon} />
                                                                                        {subSubSubMenu.name}
                                                                                    </div>

                                                                                </Link>
                                                                                {subSubSubMenu.items.length > 0 ? (
                                                                                    subSubSubMenu.toggle ?
                                                                                        <MdOutlineKeyboardArrowUp onClick={(e) => {

                                                                                            toggleDropdown(menuIndex, subSubSubIndex);
                                                                                        }} className={styles.mobile_menu_link_icon_drop_down} /> :
                                                                                        <MdOutlineKeyboardArrowDown onClick={(e) => {

                                                                                            toggleDropdown(menuIndex, subSubSubIndex);
                                                                                        }} className={styles.mobile_menu_link_icon_drop_down} />
                                                                                ) : null}
                                                                            </li>

                                                                        </>
                                                                    ))}
                                                                </ul>
                                                            )}

                                                        </>
                                                    ))}
                                                </ul>
                                            )}
                                        </>
                                    ))}
                                </ul>
                            )}
                        </>
                    ))}
                </ul>
            </div>
            }



        </>


    )
}

export default HeaderSuraj;