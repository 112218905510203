import React from 'react';
import styles from "./Unlock.module.css";

const ZohoSolutions = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textSection}>
        <h2 className={styles.title}>
          Unlock Your  <span className={styles.highlightText}>Business Potential with</span> 
          <br />
          <span className={styles.redText}>Zoho Solutions</span>
        </h2>
        
        <p className={styles.description}>
          We specialize in <strong>Zoho CRM</strong> implementations and provide <strong>Zoho CRM 
          consultation</strong> services, helping you streamline customer relationship 
          management. As leading <strong>Zoho CRM consultants in UAE</strong>, we are here to optimize 
          your sales and marketing processes, enabling you to increase conversions, 
          improve client interactions, and foster stronger business relationships.
        </p>
        
        <p className={styles.description}>
          As your <strong>Zoho Finance Partner in the UAE</strong>, we also offer comprehensive 
          solutions to streamline your financial operations using <strong>Zoho Books, Zoho 
          Invoice</strong>, and other Zoho finance tools, ensuring efficient and accurate financial 
          management.
        </p>
        <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.demoButton}>Connect with Us to Learn More</button>
      </div>

      <div className={styles.imageSection}>
        <img 
          src="https://elevateauditing.com/wp-content/uploads/2024/11/unlock-business-potential.jpg" 
          alt="Business Security with Zoho" 
          className={styles.image} 
        />
      </div>
    </div>
  );
};

export default ZohoSolutions;