import React from 'react';
import styles from './ZohoBook.module.css';

const GetStarted = () => {
    return (
        <section className={styles.getStartedSection}>
            <div className={styles.overlay}></div>
            <div className={styles.container}>
                <h2 className={styles.heading}>Get Started with Zoho Books Today</h2>
                
                <p className={styles.subheading}>
                    Take control of your accounting and financial processes with Zoho Books.
                </p>
                
                <p className={styles.description}>
                    Contact us to schedule a consultation and see how Zoho Books can transform your business. Let's work 
                    together to streamline your finances and help your business thrive.
                </p>
                
                <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.getStartedButton}>
                    Get Started
                </button>
            </div>
        </section>
    );
};

export default GetStarted;