import React from 'react';
import { motion } from 'framer-motion';
import styles from './InternalAuditProcess.module.css';

const processes = [
    {
        title: "Planning",
        icon: "/icons/planning.png",
        description: "Basic discussions regarding the scope and objectives of the audit, gathering of information regarding the organization and its processes, evaluation of existing controls and plans and audit schedule is prepared. Management is also included in this phase and all the details are documented for further actions."
    },
    {
        title: "Reporting",
        icon: "/icons/preparation.png",
        description: "The end result of the audit process is the audit report which will contain observations, findings, and auditor's opinion on the operation of the entity. The same will be prepared based on the audit workings and discussions with the management on the same."
    },
    {
        title: "Fieldwork",
        icon: "/icons/execution.png",
        description: "This stage involves implementing the audit plan drafted in the planning phase. Auditors will work closely with those in charge with the various operations, reviews the laws, policies and practices, verify the sample transactions and documents and analyses all the required data. Upon successful completion of the field work, auditors will be able to draw significant findings based on which the draft report is prepared."
    },
    {
        title: "Follow-Up Review",
        icon: "/icons/planning.png",
        description: "Upon successful submission and acceptance of the report by the management, the auditors follow-up to ensure that desired result as suggested in the report is achieved."
    }
];

const InternalAuditProcess = () => {
    return (
        <section className={styles.processSection}>
            <div className={styles.container}>
                <h2 className={styles.heading}>
                    What are the major stages in Internal Audit process?
                </h2>
                
                <div className={styles.processGrid}>
                    {processes.map((process, index) => (
                        <motion.div 
                            key={index}
                            className={styles.processCard}
                            initial={{ scale: 1 }}
                            whileHover={{ 
                                scale: 1.05,
                                transition: { duration: 0.3 }
                            }}
                        >
                            <div className={styles.cardContent}>
                                <div className={styles.iconTitle}>
                                    <img 
                                        src={process.icon} 
                                        alt={process.title} 
                                        className={styles.icon}
                                    />
                                    <h3 className={styles.title}>{process.title}</h3>
                                </div>
                                <p className={styles.description}>
                                    {process.description}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default InternalAuditProcess;
