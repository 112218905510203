import React from 'react';
import { motion } from 'framer-motion';
import styles from './OurZohoCrm.module.css';

const OurZoho = () => {
    const benefits = [
        {
            id: 1,
            title:"Consultation & Implementation:-",
            text: "We’ll understand your goals and workflows, then design and implement a Zoho CRM solution tailored to your business needs.",
            delay: 0.2
        },
        {
            id: 2,
            title:"Customization & Integration:-",
            text: "We customize Zoho CRM to fit your processes and seamlessly integrate it with your existing tools like email marketing, accounting, and customer support.",
            delay: 0.4
        },
        {
            id: 3,
            title:"Training & Support:-",
            text: "Comprehensive training and ongoing support to ensure your team uses Zoho CRM effectively and efficiently.",
            delay: 0.6
        },
        {
            id: 4,
            title:"Data Migration:-",
            text: "Offer personalized customer service through features like live chat, ticketing, and social media integrations.",
            delay: 0.8
        },
 
    ];

    return (
        <section className={styles.benefitsSection}>
            <div className={styles.container}>
      
                <div className={styles.contentWrapper}>

                <div 
                        className={styles.imageSection}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                    >
                           <h2 className={styles.heading}>
                           Our Zoho CRM Services
                            </h2>
                    <p className={styles.subtitle}>At Elevate Accounting & Auditing, we are more than just consultants – we’re your strategic partner in business growth. By leveraging Zoho CRM, we help you automate your workflows, centralize your customer data, and gain actionable insights to scale your business effectively.
            
                    </p>
                    <button
                               className={styles.button}
                               onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                               
                             >
                              Start Your Zoho CRM Journey Today
                               
                             </button>
                    </div>
             
                    <div className={styles.textContent}>
                        <div 
                            className={styles.headingContainer}
                            // initial={{ opacity: 0, y: 20 }}
                            // whileInView={{ opacity: 1, y: 0 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6 }}
                        >
                      
                        </div>

                        <div
                            className={styles.benefitsList}
                            // initial={{ opacity: 0 }}
                            // whileInView={{ opacity: 1 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                    // initial={{ opacity: 0, x: -20 }}
                                    // whileInView={{ opacity: 1, x: 0 }}
                                    // viewport={{ once: true }}
                                    // transition={{ delay: benefit.delay }}
                                >
                                    <div className={styles.checkmark}>✓</div>
                                    <h1 className={styles.subHeading}>
                                    {benefit.title}
                                    <span className={styles.text}>{benefit.text}</span>
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>
                   

                    
                </div>
            </div>
        </section>
    );
};

export default OurZoho;
