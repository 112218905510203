import styles from "./Bottom.module.css";

const Botton = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.overlay}>
        <h1 className={styles.title}>Take Your Project Management to the Next Level</h1>
        <p className={styles.text}>
        Maximize efficiency, improve collaboration, and deliver successful projects with Zoho Projects. As your Zoho Authorized Partner, Elevate Accounting & Auditing is here to guide you every step of the way.
        </p>
        <p className={styles.text}>Contact us today to schedule a consultation with one of our Zoho experts and transform your project management experience.</p>
      </div>
    </div>
  );
};

export default Botton;