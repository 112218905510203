import React from "react";
import { motion } from "framer-motion";
import styles from './ExpertAuditServices.module.css';

const services = [
  {
    title: "Authorized Zoho Partner:",
    description: "Certified expertise in Zoho Campaigns and its integrations.",
    number: "01",
  },
  {
    title: "Time bound Audits",
    description: "We deliver tailored solutions to meet your specific marketing objectives.",
    number: "02",
  },
  {
    title: "Local Understanding:",
    description: "Deep knowledge of Dubai's business landscape to drive regional success.",
    number: "03",
  },
  {
    title: "Proven Excellence:",
    description: "Track record of helping businesses achieve measurable results.",
    number: "04",
  },
 
];

const ExpertAuditServices = () => {
  return (
    <div className={styles.expertAuditContainer}>
      {/* Elementos decorativos - NUEVO */}
      <div className={styles.decorativeElements}>
        <div className={styles.topRightCircle}></div>
        <div className={styles.bottomLeftCircle}></div>
        <div className={styles.diagonalLines}></div>
        <div className={styles.dotsPattern}></div>
      </div>
     
      <div className={styles.mainContainer}>
        <div className={styles.imageContainer}>
          <img
            src="https://elevateauditing.com/wp-content/uploads/2024/11/why-choose-1.jpg"
            alt="Audit Team"
          />
        </div>
        
        {/* <div
        
          // initial={{ opacity: 0, y: 20 }}
          // animate={{ opacity: 1, y: 0 }}
          // transition={{ duration: 1 }}
        > */}
        

        <div   className={styles.servicesList}>
           <h2 className={styles.heading}>Why Choose Elevate Accounting & Auditing as Your Partner?</h2>
          {services.map((service) => (
            <div
              key={service.number} 
              className={styles.serviceItem}
              // initial={{ opacity: 0, y: 10 }}
              // animate={{ opacity: 1, y: 0 }}
              // transition={{ duration: 1 }}
            >
              <div className={styles.numberCircle}>{service.number}</div>
              <div className={styles.serviceText}>
                <h3 className={styles.elementorImageBoxTitle}>{service.title}</h3>
                <p className={styles.elementorImageBoxDescription}>{service.description}</p>
              </div>
            </div>
          ))}
              </div>
        </div>
      {/* </div> */}
    </div>
  );
};

export default ExpertAuditServices;