import styles from "./Bottom.module.css";

const Bottom2 = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.overlay}>
        <h1 className={styles.title}>Ready to Transform Your Business with Zoho One?</h1>
        <p className={styles.text} style={{fontStyle:"italic"}}>
        Unify your operations, drive efficiency, and accelerate growth with the power of Zoho One.
        </p>
        <p className={styles.text}>Contact us today to schedule your free consultation and discover how Zoho One can revolutionize your business.</p>
      </div>
    </div>
  );
};

export default Bottom2;