import styles from "./TakeYour.module.css";

const TakeYour = () => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
      <div className={styles.textSection}>
        <h2 >
          Take Your Email Marketing to the Next Level with Zoho Campaigns
        </h2>
        <p className={styles.tagline}>Simplify, Streamline, and Succeed</p>
        <p className={styles.description}>
          At Elevate Accounting & Auditing, we empower businesses in Dubai, UAE, to harness the
          full potential of Zoho Campaigns. Whether you’re starting fresh or enhancing your
          existing email strategy, we deliver customized solutions that drive results.
        </p>
      </div>
      
      <div className={styles.imageSection}>
        <img src="https://elevateauditing.com/wp-content/uploads/2024/11/email-marketing.jpg" alt="Email Marketing" className={styles.image} />
      </div>
      </div>
    
    </div>
  );
};

export default TakeYour;
