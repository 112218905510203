import React from 'react';
import { motion } from 'framer-motion';
import styles from './business.module.css';

const BusinessGrowth = () => {
    const features = [
        {
            id: 1,
            title: "Effortless GST Compliance:",
            text: " Generate tax-compliant e-invoices, e-Way bills, and delivery challans. Automatically calculate liabilities and file tax returns with ease.",
            delay: 0.2
        },
        {
            id: 2,
            title: "Connected Banking:",
            text: " Automate bank feeds, categorize transactions, and reconcile accounts swiftly to simplify your banking processes.",
            delay: 0.4
        },
        {
            id: 3,
            title: "Sell Globally with Multi-Currency Support:",
            text: " Easily handle international transactions with multi-currency support and automatic or manual exchange rate application.",
            delay: 0.6
        },
        {
            id: 4,
            title: "Seamless Collaboration:",
            text: " Collaborate efficiently with team members, customers, and vendors using secure portals and role-based permissions.",
            delay: 0.8
        },
        {
            id: 5,
            title: "Access Anywhere, Anytime:",
            text: " Manage your accounting processes from any device—web, mobile, or desktop—keeping you in control wherever you are.",
            delay: 1
        }
    ];

    return (
        <section className={styles.growthSection}>
            <div className={styles.decorativeElements}>
                <div className={styles.circle1}></div>
                <div className={styles.circle2}></div>
                <div className={styles.diagonalLines}></div>
            </div>
            
            <div className={styles.growthContainer}>
                <h1 className={styles.growthTitle}>
                    <span className={styles.darkBlue}>Unlock Business </span>
                    <span className={styles.redGradient}>Growth with </span>
                    <span className={styles.darkRed}>Zoho Books</span>
                </h1>
                
                <p className={styles.growthDescription}>
                    Zoho CRM is a versatile and robust platform that enables businesses to manage their sales, marketing, customer support, and more.
                </p>
                
                <div className={styles.featuresContainer}>
                    <div className={styles.featuresList}>
                        {features.map((feature) => (
                            <div key={feature.id} className={styles.featureItem}>
                               <div className={styles.checkmark}>✓</div>
                                <div className={styles.featureContent}>
                                    <strong className={styles.featureTitle}>{feature.title}</strong>
                                    <span className={styles.featureText}>{feature.text}</span>
                                </div>
                            </div>
                        ))}
                        
                        <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className={styles.demoButton}>Request a Demo Today</button>
                    </div>
                    
                    <div className={styles.growthImageContainer}>
                        <img 
                            src="https://elevateauditing.com/wp-content/uploads/2024/12/accounting-supervision-services.png" 
                            alt="Zoho Books Accounting"
                            className={styles.growthImage}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BusinessGrowth; 