import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './OurAuditProcedure.css';

const procedures = [
    {
        title: "Initial Planning & Documentation",
        description: "This stage involves understanding the business and its environment by collecting all relevant documents including legal, financial and such others as deemed necessary. Based on this an audit plan is made giving the course of procedure and tentative submission of draft report.",
        icon: "/icons/planning.png"
    },
    {
        title: "Execution",
        description: "Upon approval of the audit plan, field work or in-house work as deemed appropriate is carried out. Walk Through, enquiry, sampling & scrutinies are performed in this stage. Clients will be kept informed about the status of the audit.",
        icon: "/icons/execution.png"
    },
    {
        title: "Preparation of the draft audit report",
        description: "Upon completion of successful audit procedures, draft report is prepared and submitted to management along with observations & findings for their review and approval.",
        icon: "/icons/preparation.png"
    },
    {
        title: "Issue of final reports",
        description: "Once the issues and findings are discussed and appropriate changes are made to the financials, the draft report again goes for approval by both parties subsequent to which final reports are issued.",
        icon: "/icons/final-report.png"
    }
];

const cardVariants = {
    initial: {
        scale: 1,
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
    },
    hover: {
        scale: 1.05,
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)"
    }
};

const iconVariants = {
    initial: {
        y: 0
    },
    hover: {
        y: -10
    }
};

const OurAuditProcedure = () => {
    const [screenSize, setScreenSize] = useState({
        isMobile: false,
        isTablet: false
    });

    // Detect screen size
    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                isMobile: window.innerWidth < 768,
                isTablet: window.innerWidth >= 768 && window.innerWidth < 1024
            });
        };
        
        // Initial check
        handleResize();
        
        // Add event listener
        window.addEventListener('resize', handleResize);
        
        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Responsive styles
    const containerStyle = {
        background: "linear-gradient(135deg, #B02A37 0%, #4A275F 50%, #1E3A8A 100%)", 
        padding: screenSize.isMobile ? "40px 15px" : screenSize.isTablet ? "50px 20px" : "60px 20px", 
        width: screenSize.isMobile ? "100%" : screenSize.isTablet ? "90%" : "80%",
        margin: "0 auto",
        borderRadius: "20px",
        minHeight: screenSize.isMobile ? "auto" : "100vh"
    };

    const headingStyle = {
        color: "#ffffff", 
        fontSize: screenSize.isMobile ? "1.8rem" : screenSize.isTablet ? "2.2rem" : "2.5rem", 
        fontWeight: "600",
        marginBottom: screenSize.isMobile ? "30px" : "50px",
        padding: "0 10px"
    };

    const gridStyle = {
        display: "grid", 
        gridTemplateColumns: screenSize.isMobile ? "1fr" : "repeat(2, 1fr)", 
        gap: screenSize.isMobile ? "20px" : "25px", 
        width: "100%", 
        maxWidth: "1200px", 
        margin: "0 auto"
    };

    const cardStyle = {
        background: "white",
        borderRadius: screenSize.isMobile ? "25px" : "35px",
        padding: screenSize.isMobile ? "25px 20px" : screenSize.isTablet ? "30px 25px" : "35px 30px",
        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    };

    const iconStyle = {
        width: screenSize.isMobile ? "50px" : screenSize.isTablet ? "60px" : "70px", 
        height: screenSize.isMobile ? "50px" : screenSize.isTablet ? "60px" : "70px",
        marginBottom: "15px"
    };

    const titleStyle = {
        fontSize: screenSize.isMobile ? "16px" : screenSize.isTablet ? "17px" : "18.24px", 
        fontFamily: "Roboto, sans-serif",
        fontWeight: "700",
        color: "rgb(52, 52, 52)",
        margin: "0px 0px 15px"
    };

    const descriptionStyle = {
        color: "#666", 
        fontSize: screenSize.isMobile ? "14px" : "15px", 
        lineHeight: "1.6"
    };

    return (
        <div style={containerStyle}>
            <div style={{ textAlign: "center", marginBottom: screenSize.isMobile ? "30px" : "50px" }}>
                <h2 style={headingStyle}>
                    Our Audit Procedure
                </h2>
            </div>

            <div style={gridStyle}>
                {procedures.map((procedure, index) => (
                    <motion.div 
                        className="group relative" 
                        key={index}
                        variants={cardVariants}
                        initial="initial"
                        whileHover="hover"
                        transition={{ type: "spring", stiffness: 300 }}
                    >
                        <motion.div className="content">
                            <motion.img 
                                src={procedure.icon} 
                                alt={procedure.title} 
                                className="card-icon"
                                variants={iconVariants}
                            />
                            <h3 className="card-title">{procedure.title}</h3>
                            <p className="card-description">{procedure.description}</p>
                        </motion.div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default OurAuditProcedure;
