import React from 'react';
import { Link } from 'react-router-dom';
import styles from './internalaudit.module.css';
import WhyInternalAudit from './whyInternalAudit';
import NormalDuration from './normalduration';
import InternalAuditProcess from './InternalAuditprocess';
import ExpertAuditServices from '../ExternalAudit/ExpertAuditServices';
 
import InternalAudit from './Frameworks';
import AllPageHero from '../../AllPageHero';
import FreeConsultation from '../../Apurv/FooterContact';
import { useLocation } from "react-router-dom";
import Footer from '../../Footer';
import { motion } from 'framer-motion';
import SEO from '../../../SEO/SEO';
    

const ExternalAudit = () => {
         const location = useLocation();
            const currPath = location.state?.currPath || [];
    return (
        <>
        <SEO title="Internal Audit | Elevate Accounting & Auditing" description="Elevate Accounting & Auditing is your trusted partner in Dubai for internal audit services. We offer expert consultation, implementation, customization, and ongoing support to help you maximize efficiency, enhance collaboration, and drive growth. From sales and marketing to finance, HR, and customer service, streamline every aspect of your business with Zoho One. Book a free consultation today!" keywords="Internal audit Dubai, Audit services UAE, Audit firm Dubai, Internal audit UAE, Audit firm UAE, Audit services Dubai, Audit firm UAE, Audit services Dubai, Audit firm UAE, Audit services Dubai" url="https://elevatebpm.com/internal-audit/" />
            <AllPageHero currPath={currPath} headingName={"Internal Audit"}/>
            <InternalAudit />
            <WhyInternalAudit />
            <NormalDuration />
            <InternalAuditProcess />
            <ExpertAuditServices />
            <FreeConsultation />
            <Footer />
        </>

    );
};

export default ExternalAudit;
