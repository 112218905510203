import React from 'react';
import { motion } from 'framer-motion';
import styles from './NormalDuration.module.css';

const NormalDuration = () => {
    return (
        <section className={styles.durationSection}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <div className={styles.textContent}>
                        <motion.div 
                            className={styles.headingContainer}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6 }}
                        >
                            <h2 className={styles.heading}>
                                What is the normal duration of Internal Audits?
                            </h2>
                            <p className={styles.description}>
                                Unlike external audits, internal audits dive deep into the organizational structure and procedures. As such, internal audit involves working closely with the employees and management of the organization. Typical internal audits range from three to four weeks to couple of months depending on the type and complexity.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NormalDuration;
