import React from 'react';
import { motion } from 'framer-motion';
import styles from './WhyInternalAudit.module.css';

const WhyInternalAudit = () => {
    const benefits = [
        {
            id: 1,
            title:"Create Stunning Campaigns with Ease:",
            text: " Design professional email campaigns using drag-and-drop editors and customizable templates.",
            delay: 0.2
        },
        {
            id: 2,
            title:"Engage Your Audience with Precision:",
            text: " Deliver personalized messages through advanced audience segmentation.",
            delay: 0.4
        },
        {
            id: 3,
            title:"Automate Your Marketing for Maximum Efficiency:",
            text: " Simplify workflows with automated sequences, drip campaigns, and triggers.",
            delay: 0.6
        },
        {
            id: 4,
            title:"Make Data-Driven Decisions with Real-Time Insights:",
            text: " Track performance with detailed analytics for better optimization.",
            delay: 0.8
        },
        {
            id: 5,
            title:"Seamless Integration Across Zoho Apps:",
            text: " Connect Zoho Campaigns with Zoho CRM and other tools for a unified system.",
            delay: 1
        },
        {
            id: 5,
            title:"Prioritize Compliance and Security:",
            text: " Stay GDPR-compliant and safeguard subscriber data with robust features.",
            delay: 1
        }
    ];

    return (
        <section className={styles.benefitsSection}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    
                <div 
                        className={styles.imageSection}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                    >
                        <img 
                            className={styles.image}
                            src="https://elevateauditing.com/wp-content/uploads/2024/11/why-choose.jpg"
                            alt="Internal Audit Benefits"
                            loading="lazy"
                        />
                    </div>
                    <div className={styles.textContent}>
                        <div 
                            className={styles.headingContainer}
                            // initial={{ opacity: 0, y: 20 }}
                            // whileInView={{ opacity: 1, y: 0 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6 }}
                        >
                            <h2 className={styles.heading}>
                            Why choose Zoho Campaigns
                            </h2>
                    {/* <p className={styles.subtitle}>
                    Simplify email marketing with powerful tools, automation, and seamless integrations.
                    </p> */}
                        </div>

                        <div
                            className={styles.benefitsList}
                            // initial={{ opacity: 0 }}
                            // whileInView={{ opacity: 1 }}
                            // viewport={{ once: true }}
                            // transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                    // initial={{ opacity: 0, x: -20 }}
                                    // whileInView={{ opacity: 1, x: 0 }}
                                    // viewport={{ once: true }}
                                    // transition={{ delay: benefit.delay }}
                                >
                                <div className={styles.checkmark}>✓</div>

                                    <h1 className={styles.subHeading}>
                                    {benefit.title}
                                    <span className={styles.text}>{benefit.text}</span>
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default WhyInternalAudit;
