import React from 'react'
import styles from './Zohocomp.module.css'

const ZohoOneComp = () => {
  return (
    <div className={styles.main}>
        <div className={styles.main2}>
        <h2>Expert Consulting Services from Elevate Accounting & Auditing Ultimate Business Operating System</h2>
        <p>At Elevate Accounting & Auditing, your trusted Zoho Partner in Dubai, UAE, we are committed to helping businesses streamline their customer relationship management (CRM) processes with Zoho CRM. As Zoho-certified consultants, we offer expert solutions that empower you to boost productivity, enhance customer relationships, and drive business growth.</p>
        <button
            className={styles.button}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            
          >
            Book a FREE consultation
            
          </button>
        </div>
       
    </div>
  )
}

export default ZohoOneComp