import React from "react";
import { motion } from "framer-motion";
import styles from "./ProjectsServices.module.css";

const ZohoProjectsServices = () => {
  const benefits = [
    {
      id: 1,
      title: "Consultation & Setup",
      text: "We help you implement Zoho Projects based on your unique needs.",
      delay: 0.2,
    },
    {
      id: 2,
      title: "Customization & Integration",
      text: "Tailor Zoho Projects to your workflow and integrate it with your existing systems.",
      delay: 0.4,
    },
    {
      id: 3,
      title: "Training & Support",
      text: "Comprehensive training and ongoing support for your team.",
      delay: 0.6,
    },
    {
      id: 4,
      title: "Optimization & Growth",
      text: "Continuously optimize Zoho Projects as your business expands.",
      delay: 0.8,
    },
  ];

  return (
    <section className={styles.servicesSection}>
      {/* Elementos decorativos - NUEVO */}
      <div className={styles.decorativeElements}>
        <div className={styles.circleTopRight}></div>
        <div className={styles.circleBottomLeft}></div>
        <div className={styles.diagonalLines}></div>
        <div className={styles.dotsPattern}></div>
      </div>
       
      <div className={styles.container}>
        <div>
          <h2 className={styles.heading}>
            Our  <span className={styles.highlight}> Zoho Projects Servicess</span>
          </h2>
          <p className={styles.subtitle}>
            As your trusted Zoho partner in the UAE, Elevate Accounting & Auditing offers a range of services to ensure you get the most out of Zoho Projects:
          </p>
        </div>
          
        {/* Left Side (Text Content) */}
        <div className={styles.textContent}>
          <div className={styles.benefitsList}>
            {benefits.map((benefit) => (
              <motion.div
                key={benefit.id}
                className={styles.benefitItem}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: benefit.delay }}
              >
                <div className={styles.checkmark}>
                <div className={styles.checkmark}>✓</div>
                </div>
                <div>
                  <h3 className={styles.subHeading}>{benefit.title}</h3>
                  <p className={styles.text}>{benefit.text}</p>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.button
            className={styles.button}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Schedule a Consultation
          </motion.button>
        </div>

        {/* Right Side (Image) */}
        <motion.div
          className={styles.imageSection}
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          <img
            className={styles.image}
            src="https://elevateauditing.com/wp-content/uploads/2024/12/Business-Strategy-why.png"
            alt="Zoho Projects Services"
            loading="lazy"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default ZohoProjectsServices;
