"use client"

import { useEffect } from "react"
import styles from "./Zohoprojectcard.module.css"
import { Target, Users, Calendar, Clock, Smartphone, BarChart3 } from "lucide-react"

const ZohoProjectCard = () => {
  // Animation effect on scroll
  useEffect(() => {
    const cards = document.querySelectorAll(`.${styles.processCard}`)

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate)
          }
        })
      },
      { threshold: 0.1 },
    )

    cards.forEach((card) => {
      observer.observe(card)
    })

    return () => {
      cards.forEach((card) => {
        observer.unobserve(card)
      })
    }
  }, [])

  const features = [
    {
      Icon: Users,
      title: "Collaborative Workspaces",
      description: "Enhance team collaboration with a central hub for communication, document sharing, and task updates.",
      color: "#ff5733", // Red-Orange
    },
    {
      Icon: Calendar,
      title: "Easy Project Planning",
      description: "Plan projects effortlessly using Gantt charts, milestones, and task dependencies.",
      color: "#007bff", // Blue
    },
    {
      Icon: Target,
      title: "Real-Time Tracking",
      description: "Monitor project progress with live dashboards and reports for informed decision-making.",
      color: "#28a745", // Green
    },
    {
      Icon: Clock,
      title: "Time & Billing Management",
      description: "Track project hours, manage timesheets, and generate invoices with ease.",
      color: "#ffc107", // Yellow
    },
    {
      Icon: Smartphone,
      title: "Mobile Access",
      description: "Stay connected on-the-go with Zoho Projects' mobile app for iOS and Android.",
      color: "#6610f2", // Purple
    },
    {
      Icon: BarChart3,
      title: "Resource Optimization",
      description: "Manage workloads, allocate resources efficiently, and maximize productivity.",
      color: "#17a2b8", // Teal
    },
  ]

  return (
    <section className={styles.processSection}>
      <div className={styles.container}>
        <h2 className={styles.heading}>Key Features of Zoho Projects</h2>
        <p className={styles.subheading}>
          Leverage powerful tools in Zoho Projects to simplify planning, tracking, and collaboration for more efficient
          project execution
        </p>

        <div className={styles.processGrid}>
          {features.map((feature, index) => (
            <div
              key={index}
              className={`${styles.processCard} ${styles.fadeIn}`}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className={styles.cardContent}>
                <div className={styles.iconTitle}>
                  <feature.Icon className={styles.icon} stroke={feature.color} />
                  <h3 className={styles.title}>{feature.title}</h3>
                </div>
                <p className={styles.description}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ZohoProjectCard
