import React from 'react';
import { motion } from 'framer-motion';
import styles from './WhyInternalAudit.module.css';

const WhyInternalAudit = () => {
    const benefits = [
        {
            id: 1,
            text: "Are there internal controls in place for every process in the organization?",
            delay: 0.2
        },
        {
            id: 2,
            text: "Are these controls effective?",
            delay: 0.4
        },
        {
            id: 3,
            text: "Whether the entities risk assessment and mitigation procedures are appropriate?",
            delay: 0.6
        },
        {
            id: 4,
            text: "Whether opportunities for improvements are identified?",
            delay: 0.8
        },
        {
            id: 5,
            text: "Whether remedial actions are adopted and implemented?",
            delay: 1
        }
    ];

    return (
        <section className={styles.benefitsSection}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <div className={styles.textContent}>
                        <motion.div 
                            className={styles.headingContainer}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6 }}
                        >
                            <h2 className={styles.heading}>
                                Why should an organization have Internal Audit?
                            </h2>
                            <p className={styles.subtitle}>
                                Conducting internal audits are crucial to understand the following:
                            </p>
                        </motion.div>

                        <motion.div
                            className={styles.benefitsList}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {benefits.map((benefit) => (
                                <motion.div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                    initial={{ opacity: 0, x: -20 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: benefit.delay }}
                                >
                                 <div className={styles.checkmark}>✓</div>
                                    <span className={styles.text}>{benefit.text}</span>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>

                    <motion.div 
                        className={styles.imageSection}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                    >
                        <img 
                            className={styles.image}
                            src="https://media.licdn.com/dms/image/D5612AQH83WZKAy7hbA/article-cover_image-shrink_720_1280/0/1717186680924?e=2147483647&v=beta&t=KdhfROwoidWvn0O7-gq7VCbqnB3GAbBnXOBeomMcy08"
                            alt="Internal Audit Benefits"
                            loading="lazy"
                        />
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default WhyInternalAudit;
