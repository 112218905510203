import React from "react";
import styles from "./ElevateAccounting.module.css";
import Hero from '../HomeComp/Hero.js';

// const ElevateAccounting = () => {
//   return (
//     <div className={styles.gridContainer}>
//       <div className={styles.leftColumn}>
//         <div className={styles.textSection}>
//           <h1 className={styles.heading}>
//             Elevate Accounting & Auditing: Your Trusted
//             <span className={styles.highlight}> Zoho Partner in the UAE</span>
//           </h1>
//           <p className={styles.paragraph}>
//             At <strong className={styles.strong}>Elevate Accounting & Auditing</strong>
//             we help businesses enhance their HR management with <strong className={styles.strong}>Zoho People</strong>, a comprehensive solution for streamlining processes, improving employee engagement, and driving productivity. As an authorized Zoho partner, we offer tailored services to ensure Zoho People fits your specific HR needs.
//           </p>
//         </div>
//         <button className={styles.button}>
//           Book Your Free Consultation
//         </button>
//       </div>
//       <div className={styles.rightColumn}>
//         {/* Right column is intentionally left empty */}
//       </div>
//     </div>
//   );
// };

// import React from 'react'

const ElevateAccounting = () => {
  const title = "Elevate Accounting & Auditing: Your Trusted Zoho Partner in the UAE";
  const description = (
    <>At <strong>Elevate Accounting & Auditing</strong>, we help businesses enhance their HR management with <strong>Zoho People</strong>, a comprehensive solution for streamlining processes, improving employee engagement, and driving productivity. As an authorized Zoho partner, we offer tailored services to ensure Zoho People fits your specific HR needs.</>
  );
  const buttonText = "Get in Touch";
  const buttonLink = "/contact";
  const lottiejson = "https://images.unsplash.com/photo-1664575602276-acd073f104c1?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
  
  return (
    <div className={styles.heroWrapper}>
      {/* Elementos decorativos */}
      <div className={styles.decorativeElements}>
        <div className={styles.circleTopRight}></div>
        <div className={styles.circleBottomLeft}></div>
        <div className={styles.diagonalLines}></div>
        <div className={styles.dotsPattern}></div>
      </div>
      
      {/* Componente Hero original */}
      <Hero 
        title={title} 
        description={description} 
        buttonText={buttonText} 
        buttonLink={buttonLink} 
        lottiejson={lottiejson} 
        bg={"white"}
      />
    </div>
  );
};

export default ElevateAccounting;

