import React from 'react';
import { motion } from 'framer-motion';
import styles from './WhyChooseCon.module.css';

const WhyChooseCon = () => {
    const benefits = [
        {
            id: 1,
            title:"Authorized Zoho Partner,",
            text: " Access all your customer information from a single platform, allowing your team to engage with clients more efficiently and make informed decisions.",
            delay: 0.2
        },
        {
            id: 2,
            title:"Deep knowledge of Zoho CRM's features",
            text: " Automate repetitive tasks such as follow-up emails, lead assignments, and notifications, freeing up time to focus on revenue-generating activities.",
            delay: 0.4
        },
        {
            id: 3,
            title:"Personalized solutions tailored to your business needs",
            text: " Use advanced reporting and analytics tools to uncover trends, optimize your sales pipeline, and understand customer behavior.",
            delay: 0.6
        },
        {
            id: 4,
            title:"Proven track record of successful CRM implementations",
            text: " Offer personalized customer service through features like live chat, ticketing, and social media integrations.",
            delay: 0.8
        },
    ];

    return (
        <section className={styles.benefitsSection}>
            <div className={styles.decorativeElements}>
                <div className={styles.circleTopRight}></div>
                <div className={styles.circleBottomLeft}></div>
                <div className={styles.diagonalPattern}></div>
                <div className={styles.dotPattern}></div>
            </div>
            
            <div className={styles.container}>
                <h2 className={styles.heading}>
                    Why Choose Elevate Accounting & Auditing for Zoho CRM Consulting?
                </h2>
                 
                <div className={styles.contentWrapper}>
                    <div 
                        className={styles.imageSection}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                    >
                        <img 
                            className={styles.image}
                            src="https://elevateauditing.com/wp-content/uploads/2024/11/why-choose-elevate-accounting-auditing-for-zoho-crm-consulting.jpg"
                            alt="Internal Audit Benefits"
                            loading="lazy"
                        />
                    </div>

                    <div className={styles.textContent}>
                        <p className={styles.subtitle}>
                            At Elevate Accounting & Auditing, we are more than just consultants – we're your strategic partner in business growth. By leveraging Zoho CRM, we help you automate your workflows, centralize your customer data, and gain actionable insights to scale your business effectively.
                        </p>
                        <h3 className={styles.ourExpert}>Our Expertise</h3>

                        <div className={styles.benefitsList}>
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className={styles.benefitItem}
                                >
                                   <div className={styles.checkmark}>✓</div>

                                    <h1 className={styles.subHeading}>
                                        {benefit.title}
                                    </h1>
                                </div>
                            ))}
                        </div>
                        <button
                            className={styles.button}
                            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                        >
                            Let's Transform Your Business with Zoho CRM
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseCon;
