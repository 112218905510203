import React from 'react'
import styles from './Zohocomp.module.css'
const ZohoOneComp = () => {
  return (
    <div className={styles.main}>
        <div className={styles.main2}>
        <h2>Empower Your Organization with the Ultimate Business Operating System</h2>
        <p>At Elevate Accounting & Auditing, we are committed to helping businesses in the UAE optimize their operations using Zoho One, the all-in-one business solution. As an
        authorized Zoho partner, we offer comprehensive implementation, support, and customization services to unlock the full potential of Zoho One for your business.</p>
        <p>Zoho One is a unified suite of 45+ integrated applications designed to streamline business processes, enhance collaboration, and accelerate growth across all departments. From sales to HR, finance to marketing, Zoho One brings your teams together with a seamless flow of data and insights.</p>
        <button
            className={styles.button}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            
          >
           Get Expert Support
            
          </button>
        </div>
       
    </div>
  )
}

export default ZohoOneComp