"use client"
import React, { useState, useEffect, useRef } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import styles from "./hero.module.css"
import { useNavigate } from 'react-router-dom';
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from "framer-motion";

const ROTATION_RANGE = 32.5;
const HALF_ROTATION_RANGE = ROTATION_RANGE / 2;

const Hero = ({ title, description, buttonText, buttonLink, lottiejson, showStats, bg}) => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleButtonClick = () => {
    window.open(buttonLink, '_blank');
  };

  const isMobile = windowWidth <= 768;
  const isTablet = windowWidth <= 1024 && windowWidth > 768;

  const imgRef = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const xSpring = useSpring(x);
  const ySpring = useSpring(y);

  const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

  const handleMouseMove = (e) => {
    if (!imgRef.current) return [0, 0];

    const rect = imgRef.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
    const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

    const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
    const rY = mouseX / width - HALF_ROTATION_RANGE;

    x.set(rX);
    y.set(rY);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <div className={styles.main} style={{ backgroundColor:bg || "whitesmoke"}}>
      <div className={styles.main2}>
      
        <div>
          <h2 style={{ fontSize: "28px", fontWeight: "bold" }}>{title}</h2>
          <p>{description}</p>
          {showStats === true && (
            <div className={styles.stats} style={{marginTop:"15px"}}> 
              <div>
                <div>
                  <h3>4+</h3>
                <p>Countries</p>
              </div>
              <div>
                <h3>40+</h3>
                <p>Professionals</p>
              </div>
              <div>
                <h3>99+</h3>
                <p>Corporates</p>
              </div>
            </div>
            {/* <div>
              <div>
                <h3>Trusted Excellence</h3>
                <p>Over a decade of proven expertise in audit and accounting services</p>
              </div>
              <div>
                <h3>Industry Leaders</h3>
                <p>Pioneering solutions for businesses across multiple sectors</p>
              </div>
            </div> */}

          </div>)}
          <button className={styles.know} onClick={handleButtonClick}>{buttonText}</button>
        </div>
        <div>
          {/* <Player
          autoplay
          loop
          src={lottiejson}
          style={{ height: "auto", width: "100%" }}
        >
          <Controls
            visible={false}
            buttons={["play", "repeat", "frame", "debug"]}
          />
        </Player> */}
          <img
            ref={imgRef}
            // src="https://images.unsplash.com/photo-1707157281599-d155d1da5b4c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            src={lottiejson}
            alt={title}
            width={"100%"}
            height={"100%"}
            style={{
              width: 'auto',
              height: 'auto',
              marginLeft: isMobile ? '0' : isTablet ? '20px' : '0px',
              marginTop: isMobile ? '40px' : isTablet ? '20px' : '0px',
              maxWidth: isMobile ? '100%' : '600px',
              objectFit: 'contain',
              borderRadius: "20px",
              padding: "5px",
              backgroundColor: 'white',
              transformStyle: "preserve-3d",
              transform,
            }}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        </div>
    </div>
  )
}

export default Hero