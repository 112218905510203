import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ExternalAudit.module.css';

import { motion } from 'framer-motion';
import Benefits from './Benefits';
import BuildingConfidence from './BuildingConfidence';
import OurAuditProcedure from './OurAuditProcedure';
import ExpertAuditServices from './ExpertAuditServices';
import FreeZones from './freeZones';
import Consult from './Consult';
import FreeConsultation from '../../Apurv/FooterContact';
import AllPageHero from '../../AllPageHero';
import { useLocation } from "react-router-dom";
import Footer from '../../Footer';
 import SEO from '../../../SEO/SEO';

const ExternalAudit = () => {
        const location = useLocation();
        const currPath = location.state?.currPath || [];
    return (

        
        <>
        <SEO title="External Audit | Elevate Accounting & Auditing" description="Elevate Accounting & Auditing is your trusted partner in Dubai for external audit services. We offer expert consultation, implementation, customization, and ongoing support to help you maximize efficiency, enhance collaboration, and drive growth. From sales and marketing to finance, HR, and customer service, streamline every aspect of your business with Zoho One. Book a free consultation today!" keywords="External audit Dubai, Audit services UAE, Audit firm Dubai, External audit UAE, Audit firm UAE, Audit services Dubai, Audit firm UAE, Audit services Dubai, Audit firm UAE, Audit services Dubai" url="https://elevatebpm.com/external-audit/" />
           <AllPageHero currPath={currPath} headingName={"External Audit"}/>
            <BuildingConfidence />
            <Benefits />
            <OurAuditProcedure />
            <ExpertAuditServices />
            <FreeZones />
            <Consult />
            <FreeConsultation/>
            <Footer/>
        </>
    );
};

export default ExternalAudit;
