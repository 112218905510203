import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import { useLoading } from "./context/LoadingContext";
import ScrollToTop from "./Components/ScrollToTop";
import Zohopartner from "./Components/SatyaZohoProject3rd/Zohopartner";
import Header from "./Components/Header";
import ExternalAudit from "./Components/HomeComp/ExternalAudit/ExternalAudit";
import InternalAudit from "./Components/HomeComp/internal-audit/internalaudit";
import HeaderSuraj from "./Components/Suraj/Header";
import ZohoCampaigns from "./Components/Suraj/zoho/Zoho Campaigns/App";
import Books from "./Components/SatyaZoho6Book/books";
import Partner  from "./Components/ZohoAllComp/ZohoPartnerDubai";

// import CoomingSoon from "./Components/CoomingSoon";

import ZohoCRM from "./Components/Suraj/zoho/Zoho CRM/App.js";
import ZohoPartnerDubai from "./Components/ZohoAllComp/ZohoPartnerDubai";
import CoomingSoon from "./Components/CoomingSoon";

import ZohoPeople from "./Components/ApurvZohoPeople/ZohoPeople";
import ZohoOne from "./Components/ApurvZohoPeople/ZohoOne";


// Lazy-loaded components
const Home = lazy(() => import("./Components/Home"));
const HomeOrg = lazy(() => import("./Components/HomeOrg"));
const About = lazy(() => import("./Components/About"));
const Blog = lazy(() => import("./Components/Blog"));
const Careers = lazy(() => import("./Components/Careers"));
const BlogDetails = lazy(() => import("./Components/BlogDetails"));

const Contact = lazy(() => import("./Components/Contact"));
const Loader = lazy(() => import("./Components/common/Loader"));
const PageSectionBySlug = lazy(() => import("./Components/common/PageSectionBySlug"));
const NotFound = lazy(() => import("../src/Components/common/PageNotFound"));


function App() {
  const { loading } = useLoading();

  return (
    <>
      {/* Display loader if loading is true */}
      {loading && <Loader />}

      <BrowserRouter>
        <ScrollToTop />
        <HeaderSuraj />

        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomeOrg />} />
            <Route path="zoho-campaigns" element={<ZohoCampaigns/>} />
            <Route path="zoho-people" element={<ZohoPeople />} />
            <Route path="zoho-one" element={<ZohoOne />} />
            <Route path="zoho-books" element={<Books/>} />
            {/* <Route path="zoho-books" element={<CoomingSoon />} /> */}
            <Route path="zoho-crm-consulting" element={<ZohoCRM/>} />

            <Route path="home" element={<HomeOrg />} />
            <Route path="zoho-partner-dubai" element={<Partner/>} />
            <Route path="about-us" element={<About />} />
            <Route path="blogs" element={<Blog />} />
            <Route path="blog/details/:id" element={<BlogDetails />} />
        
            <Route path="career" element={<Careers />} />
            <Route path="contact" element={<Contact />} />
            <Route path="elevate-your-project" element={<Zohopartner />} />
            <Route path="external-audit" element={<ExternalAudit />} />
            <Route path="internal-audit" element={<InternalAudit />} />



            <Route path=":slug" element={<PageSectionBySlug />} />
            <Route path="page-not-found" element={<NotFound />} />

          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;



























// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Header from "./Components/Header";
// import Footer from "./Components/Footer";
// import { lazy, Suspense } from "react";
// import { useLoading } from "./context/LoadingContext";
// import ScrollToTop from "./Components/ScrollToTop";
// import HomeOrg from "./Components/HomeOrg";

// const Home = lazy(() => import("./Components/Home"));
// const About = lazy(() => import("./Components/About"));
// const Blog = lazy(() => import("./Components/Blog"));
// const Careers = lazy(() => import("./Components/Careers"));
// const Blog_details = lazy(() => import("./Components/BlogDetails"));
// const Contact = lazy(() => import("./Components/Contact"));
// const Loder = lazy(() => import("./Components/common/Loader"));
// const PageSectionBySlug = lazy(() =>
//   import("./Components/common/PageSectionBySlug")
// );

// function App() {
//   const { loading, setLoading } = useLoading();
//   return (
//     <>
//       {/* {loading && <Loder />} */}
//       <BrowserRouter>
//         <ScrollToTop />
//         <Header/>
//         <Suspense fallback={<Loder />}>
//           <Routes>
//             <Route exact path="/">
//               <Route index element={<HomeOrg />} />
//               <Route path="home" element={<HomeOrg />} />
//               <Route path="/about-us" element={<About />} />
//               <Route path="blogs" element={<Blog />} />
//               <Route path="blog/details/:id" element={<Blog_details />} />
//               <Route path="career" element={<Careers />} />
//               <Route path="contact" element={<Contact />} />
//               <Route path=":slug" element={<PageSectionBySlug />} />
//             </Route>
//           </Routes>
//         </Suspense>
//       </BrowserRouter>
//     </>
//   );
// }

// // function wait(time) {
// //     return new Promise(resolve => {
// //         setTimeout(resolve, time)
// //     })
// // }

// export default App;
