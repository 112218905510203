import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import instance from "../api/api_instance";
import toastr from "toastr";
import SubMenu from "./common/MenuSetting";
import $ from "jquery";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HeaderSkeleton from "./common/HeaderSkeleton";
export default function Header() {
  const [menu, setMenu] = useState([]);
  const [subMenu, setSubMenu] = useState(null);
  const [settings, setSettings] = useState([]);
  const [headerLoading, setHeaderLoading] = useState(true);


  const mainLogo = "https://elevatebpm.com/admin/assets/media/img/logo.png";

  const getAllMenu = async () => {
    // Check if the menu data is cached in localStorage
    const cachedMenu = localStorage.getItem("cachedMenu");
    if (cachedMenu) {
      setMenu(JSON.parse(cachedMenu));  // Use the cached data
    } else {
      await instance
        .post(`/getAllMenus`)
        .then((res) => {
          setMenu(res.data.data);
          // Cache the menu data
          localStorage.setItem("cachedMenu", JSON.stringify(res.data.data));
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const getSettingData = async () => {
    setHeaderLoading(true);
    // Check if the settings data is cached in localStorage
    const cachedSettings = localStorage.getItem("cachedSettings");
    if (cachedSettings) {
      setSettings(JSON.parse(cachedSettings));  // Use the cached data
    } else {
      try {
        const res = await instance.get("/getAllsettings");
        let socialLinks = res.data.data.filter((item) =>
          Object.keys(socialMediaKeys).includes(item.key)
        );
        setSettings(socialLinks);
        // Cache the settings data
        localStorage.setItem("cachedSettings", JSON.stringify(socialLinks));
      } catch (error) {
        toastr.error(error.message);
      }
    }
    setHeaderLoading(false);
  };

  const socialMediaKeys = {
    social_link_x: "X",
    social_link_youtube: "YouTube",
    social_link_facebook: "Facebook",
    social_link_linkedin: "LinkedIn",
    whatsapp: "Whatsapp",
    instagram: "instagram",
    tiktok: "tiktok",
  };

  // Event listener for mouse hover to show submenu
  const handleMouseEnter = (item) => {
    if (item.children && item.children.length > 0) {
      setSubMenu(item);
    } else {
      setSubMenu(null); // Clear submenu if no children
    }
  };

  useEffect(() => {
    getAllMenu();
    getSettingData();
  }, []);

  // const socialLinks = settings.filter((item) =>
  //   Object.keys(socialMediaKeys).includes(item.key)
  // );


  const tohidesidebar = () => {
    if (window.innerWidth > 1024) {
      var sidebarmenuvar = document.getElementById("hideonclick");
      var burgericons = document.getElementById("menove-burger-ione");
      var iconcirele = document.getElementById("iconsidebars");
      var iconsidebarstwo = document.getElementById("iconsidebarstwo");
      var iconsidebarsthree = document.getElementById("iconsidebarsthree");
      var removescrollbars = document.getElementById("body-sections");
      var removescrollbarss = document.getElementById("body-sectionss");
      removescrollbars.classList.remove("hidden-scroll");
      removescrollbarss.classList.remove("hidden-scroll");
      burgericons.classList.remove("open");
      iconcirele.classList.remove("collapse");
      iconcirele.classList.remove("rotate30");
      iconcirele.classList.remove("rotate45");
      iconsidebarstwo.classList.remove("collapse");
      iconsidebarstwo.style.display = "block";
      iconsidebarsthree.classList.remove("collapse");
      iconsidebarsthree.classList.remove("rotate150");
      iconsidebarsthree.classList.remove("rotate135");
      if (sidebarmenuvar.style.display === "none") {
        sidebarmenuvar.style.display = "block";
      } else {
        sidebarmenuvar.style.display = "none";
      }
    }
  };

  useEffect(() => {
    $(function () {
      $(window).on("scroll", function () {
        if ($(window).scrollTop() > 50) {
          $("#header-sections").addClass("stickyactive");
        } else {
          $("#header-sections").removeClass("stickyactive");
        }
      });
      $(window).scroll(function () {
        if ($(this).scrollTop() !== 0) {
          $(".scroll-up").fadeIn(700);
          // $('.header-menu').fadeOut(700);
          // $('.user-icon').css({ 'opacity': '0', 'z-index': '0' });
        } else {
          $(".scroll-up").fadeOut(700);
          // $('.header-menu').fadeIn(700);
          // $('.user-icon').css({ 'opacity': '1', 'z-index': '9999' });
        }
      });
    });
    //Click event to scroll to top
    $(".scroll-up").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 700);
    });
  }, []);

  return (
    <>
      <div className="header aem-GridColumn aem-GridColumn--default--12" >
        <header>
          <nav
            className="navbar navbar-default navbar-fixed-top scrollbg-show"
            id="header-sections"
            role="navigation"
            style={{ padding: "unset !important" }}
          >
            <div className="container mt45">
              <div className="navbar-header page-scroll" id="logostylemob">
                <NavLink className="navbar-brand" to="/">
                  {mainLogo ? (
                    <img src={mainLogo} alt="Logo" />
                  ) : (
                    <h4>Elevate</h4>
                  )}
                </NavLink>
              </div>
              {/*  */}
              <div className="header-menu" id="mobile-headermenus">
                <ul className="nav navbar-nav navbar-right pos-right" style={{fontFamily: 'Poppins, sans-serif', fontWeight: '500', fontSize: '16px'}}>

                  {headerLoading === true ? [1, 2, 3, 4,5].map(() => (
                    <Skeleton
                      width={100}
                      height={20}
                      className="rounded-lg"
                      style={{ marginRight: "20px" }}
                    />
                  )) : menu
                    .filter((item) => item.children.length === 0)
                    .map((item, index) => (
                      <li key={item.id}>
                        {" "}
                        <NavLink
                          to={item.url === "/home" ? "/" : "/" + item.url}
                          title={item?.title}
                          style={{fontFamily: 'Poppins, sans-serif', fontWeight: '500', fontSize: '14px'}}
                        >
                          {item?.title}
                        </NavLink>{" "}
                      </li>
                    ))}
                </ul>
              </div>
              {/* <div className="search__color">
                <svg className="hidden1">
                  <defs>
                    <symbol id="icon-search" viewBox="0 0 24 24">
                      <title>search</title>
                      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                    </symbol>
                    <symbol id="icon-cross" viewBox="0 0 24 24">
                      <title>cross</title>
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </symbol>
                  </defs>
                </svg>
                <div className="search">
                  <button
                    id="btn-search-close"
                    className="btn1 btn--search-close"
                    aria-label="Close search form"
                  >
                    close
                  </button>
                  <div className="search__inner search__inner--up">
                    <form className="search__form" action="" method="get">
                      <input
                        className="search__input"
                        name="k"
                        type="search"
                        placeholder="What are you looking for?"
                        autoComplete="off"
                        spellCheck="false"
                      />
                      <span className="search__info">
                        Hit enter to search or ESC to close
                      </span>
                    </form>
                  </div>
                  <div className="search__inner search__inner--down">
                    <div className="search__related"></div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="progressbar">
              <div className="width"></div>
            </div>
          </nav>
        </header>
      </div>
      <div className="experiencefragment aem-GridColumn aem-GridColumn--default--12">
        <div className="xf-content-height">
          <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
            <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">
              {/* <!-- Burger Menu START --> */}
              <div className="burger-search-wrapper navbar-fixed-top">
                <div className="container">
                  <div className="hamburger-menu">
                    <div className="menu-bg"></div>
                    <div className="circle"></div>
                    <div className="menu" id="hideonclick">
                      <div className="fix-menu hidden-sm hidden-xs">
                        <div className="col-md-3 col-sm-12 col-xs-12 automate">
                          <NavLink to="#" title="Strengthen the root">
                            <p className="head-txt">Strengthen the root</p>
                            <p className="sub-head hidden-sm hidden-xs">
                              Select your partner to nurture your core business
                              assets
                            </p>
                          </NavLink>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12 innovate">
                          <NavLink to="#" title="Digital Transformation">
                            <p className="head-txt">Digital Transformation</p>
                            <p className="sub-head hidden-sm hidden-xs">
                              Leveraging new technologies to enhance business
                              performance
                            </p>
                          </NavLink>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12 learn">
                          <NavLink to="#" title="Explore new horizons">
                            <p className="head-txt">Explore new horizons</p>
                            <p className="sub-head hidden-sm hidden-xs">
                              Leveraging new technologies to enhance business
                              performance
                            </p>
                          </NavLink>
                        </div>
                      </div>
                      {/* <!-- Sub Menus --> */}
                      {subMenu && <SubMenu subMenu={subMenu} />}
                      {/* <!-- Side Menu Title --> */}
                      <div className="col-12 col-lg-3 col-sm-12 col-xs-12 menuItems">
                        <NavLink to="/">
                          {/* <!-- <img src="#" alt="#" className="img-responsive logo-inner"> --> */}
                          <h4>Elevate</h4>
                        </NavLink>
                        <ul className="list-unstyled">
                          {/* {menu.map((item, index) => (
                                                        <li> <NavLink to={item.url === "/home" ? "/" : item.url} title={item?.title}>{item?.title}</NavLink> </li>
                                                    ))}  */}
                          {/* className="hover-menu-hide" */}
                          {menu.map((item, index) => (
                            <li
                              key={index}
                              className={
                                item?.children?.length === 0
                                  ? "hover-menu-hide burger open"
                                  : "services"
                              }
                              title={item?.title}
                              onMouseEnter={() => handleMouseEnter(item)}
                            >
                              {/* <NavLink to={item.url === "/home" ? "/" : item.url} title={item?.title} onClick={tohidesidebar} target="_self">
                                                                {item?.title}
                                                                <span className="un-line hidden-sm hidden-xs hidden-tab"></span>
                                                            </NavLink> */}
                              <NavLink
                                to={
                                  item?.url !== "other-services"
                                    ? "/" + item.url
                                    : null
                                }
                                title={item?.title}
                                onClick={tohidesidebar}
                                target="_self"
                              >
                                {item?.title}
                                <span className="un-line hidden-sm hidden-xs hidden-tab"></span>
                              </NavLink>
                            </li>
                          ))}
                          {settings.map((link) => (
                            <li
                              className="social-icons"
                              title="Twitter"
                              key={link.key}
                            >
                              <Link
                                title={socialMediaKeys[link.key]}
                                target="_blank"
                                to={link.value}
                              >
                                <img
                                  src={`../asstes/img/${socialMediaKeys[
                                    link.key
                                  ].toLowerCase()}-icon.png`}
                                  width="16"
                                  height="16"
                                  alt={socialMediaKeys[link.key]}
                                />
                              </Link>
                            </li>
                          ))}

                          {/* <li className="social-icons" title="Instagram">
                            <NavLink
                              to="#"
                              title="Instagram"
                              className=""
                              target="_blank"
                            >
                              <img
                                src="./asstes/img/instagram-icon.png"
                                width="18px"
                                height="18px"
                                title=""
                              />
                            </NavLink>
                          </li>
                          <li className="social-icons" title="Facebook">
                            <NavLink
                              to="#"
                              title="Facebook"
                              className=""
                              target="_blank"
                            >
                              <img
                                src="./asstes/img/facebook-icon.png"
                                width="18px"
                                height="18px"
                                title=""
                              />
                            </NavLink>
                          </li>
                          <li className="social-icons" title="Linked in">
                            <NavLink
                              to="https://www.linkedin.com/company/infosys-bpm/"
                              title="Linked in"
                              className=""
                              target="_blank"
                            >
                              <img
                                src="./asstes/img/linkedin-icon.png"
                                width="18px"
                                height="18px"
                                title=""
                              />
                            </NavLink>
                          </li>
                          <li className="social-icons" title="YouTube">
                            <NavLink
                              to="https://www.youtube.com/c/infosysbpm"
                              title="YouTube"
                              className=""
                              target="_blank"
                            >
                              <img
                                src="./asstes/img/youtube-icon.png"
                                width="18px"
                                height="18px"
                                title=""
                              />
                            </NavLink>
                          </li>
                          <li className="social-icons" title="Tiktok">
                            <NavLink
                              to="#"
                              title="Tiktok"
                              className=""
                              target="_blank"
                            >
                              <img
                                src="../asstes/img/tiktok-icon.png"
                                width="18px"
                                height="18px"
                                title=""
                              />
                            </NavLink>
                          </li>
                          <li className="social-icons" title="Whatsapp">
                            <NavLink
                              to="#"
                              title="Tiktok"
                              className=""
                              target="_blank"
                            >
                              <img
                                src="../asstes/img/whatsapp-icon.png"
                                width="18px"
                                height="18px"
                                title=""
                              />
                            </NavLink>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="burger" id="menove-burger-ione">
                      <div className="icon-bar1" id="iconsidebars"></div>
                      <div className="icon-bar2" id="iconsidebarstwo"></div>
                      <div className="icon-bar3" id="iconsidebarsthree"></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Burger Menu End -->  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
